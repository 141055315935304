import { PlaylistType } from '../playlist/types';

export const enhancePlaylistMap: { [key: string]: Object } = {
  default: {
    imageSrc: '/covers/default.jpg',
    fromLang: 'en',
    toLang: 'ru',
  },
  christmas: {
    imageSrc: '/covers/christmas.jpg',
    fromLang: 'en',
    toLang: 'ru',
  },
  valentine: {
    imageSrc: '/covers/valentine.jpg',
    fromLang: 'en',
    toLang: 'ru',
  },
  parisien: {
    imageSrc: '/covers/parisien.jpg',
    fromLang: 'fr',
    toLang: 'ru',
  },
};

export const enhanceLibrary = (library: PlaylistType[]) =>
  library.map((item) => ({ ...item, ...enhancePlaylistMap[item.slug] }));
