import { AuthState, AuthActionTypes } from './types';

const INITIAL_STATE: AuthState = {
  user: null,
  // user token
  token: '',
  // current song
  song: '',
  loading: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case AuthActionTypes.GET_USER_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.GET_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case AuthActionTypes.GET_USER_FAILURE:
      return { ...state, loading: false };
    case AuthActionTypes.SET_USER_PARAMS:
      return {
        ...state,
        token: action.payload.token,
        song: action.payload.song,
      };
    case AuthActionTypes.LOGOUT:
      return { ...state, ...INITIAL_STATE };

    case AuthActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return { ...state, user: { ...state.user, settings: action.payload } };

    default:
      return state;
  }
}
