import styled from 'styled-components';

const FloatButtonContainer = styled.div`
  position: fixed;
  bottom: 75px;
  width: 100%;
  padding: 0 15px;
  text-align: center;
`;

export default FloatButtonContainer;
