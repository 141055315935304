import styled from 'styled-components';
import {
  space,
  alignItems,
  justifyContent,
  flexDirection,
  SpaceProps,
  JustifyContentProps,
  AlignItemsProps,
  FlexDirectionProps,
} from 'styled-system';

type Props = SpaceProps & JustifyContentProps & AlignItemsProps & FlexDirectionProps;

const Flex = styled.div<Props>`
  display: flex;
  ${alignItems}
  ${flexDirection}
  ${justifyContent}
  ${space}
`;

export default Flex;
