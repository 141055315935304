import { put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { LibraryActionTypes } from './types';
import { enhanceLibrary } from './normalize';

function* getLibrarySaga() {
  try {
    const data = yield api.getLibrary();
    yield put({
      type: LibraryActionTypes.GET_LIBRARY_SUCCESS,
      payload: enhanceLibrary(data),
    });
  } catch (error) {
    yield put({ type: LibraryActionTypes.GET_LIBRARY_FAILURE });
  }
}

export function* RequestsSagaWatcher() {
  yield takeLatest(LibraryActionTypes.GET_LIBRARY_REQUEST, getLibrarySaga);
}

export default RequestsSagaWatcher;
