import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.progress};
  width: 100%;
`;

const ProgressBar = styled.div`
  position: relative;
  height: 3px;
  background-color: ${(props) => props.theme.progressFill};
  width: ${(props) => props.value}%;
  transition: all 2.5s ease-out;
`;

const Progress = ({ value }) => {
  return (
    <Bar>
      <ProgressBar value={value} />
    </Bar>
  );
};

export default Progress;
