import { put, takeLatest, call } from 'redux-saga/effects';
import api from '../../api';
import { AuthActionTypes } from './types';
import { removeToken } from '../../api/request';
import { push } from 'connected-react-router';
import { event } from '../../lib/gtag';

function* getUserSaga(action: any) {
  try {
    const user = yield api.getUser(action.payload);
    yield put({ type: AuthActionTypes.GET_USER_SUCCESS, payload: user });

    if (user.settings.introVisited === false) {
      event({
        category: 'Intro',
        action: 'Start',
      });
    }
  } catch (error) {
    yield put({ type: AuthActionTypes.GET_USER_FAILURE });
  }
}

function* updateUserSettingsSaga(action: any) {
  const { id, settings } = action.payload;
  try {
    // TODO: Ask backend return new settings model
    yield api.updateUserSettings(id, settings);

    yield put({
      type: AuthActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
      payload: settings,
    });

    if (settings.introVisited === true) {
      event({
        category: 'Intro',
        action: 'End',
      });
    }
  } catch (error) {
    yield put({ type: AuthActionTypes.UPDATE_USER_SETTINGS_FAILURE });
  }
}

function* logoutSaga() {
  yield call(removeToken);
  yield put(push('/'));
}

export function* RequestsSagaWatcher() {
  yield takeLatest(AuthActionTypes.GET_USER_REQUEST, getUserSaga);
  yield takeLatest(AuthActionTypes.UPDATE_USER_SETTINGS_REQUEST, updateUserSettingsSaga);
  yield takeLatest(AuthActionTypes.LOGOUT, logoutSaga);
}

export default RequestsSagaWatcher;
