export type LibraryState = {
  collection: Object[];
  loading: boolean;
};

export enum LibraryActionTypes {
  GET_LIBRARY_REQUEST = '@@library/GET_LIBRARY_REQUEST',
  GET_LIBRARY_SUCCESS = '@@library/GET_LIBRARY_SUCCESS',
  GET_LIBRARY_FAILURE = '@@library/GET_LIBRARY_FAILURE',
}
