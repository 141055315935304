import { takeLatest, put, select } from 'redux-saga/effects';
import api from '../../api';
import { PaymentActionTypes, PaymentStartAction } from './types';
import { paymentComplete, paymentError } from './actions';
import { getUser } from '../auth/actions';
import { AppState } from '../../redux/store';
import { SongActionTypes } from '../song/types';
import * as gtag from '../../lib/gtag';

function* runPaymentWidget(payUrl: string) {
  const data = yield QiwiCheckout.openInvoice({ payUrl });
  console.log(data);
  return data;
}

function* paymentSaga({ payload: { userId, songSlug, callback } }: PaymentStartAction) {
  try {
    const { user, selectedSong } = yield select((state: AppState) => ({
      user: state.auth.user,
      selectedSong: state.song.selected,
    }));

    const data: { redirectUrl: string } = yield api.payment({
      userId,
      songSlug,
    });

    yield runPaymentWidget(data.redirectUrl);

    // Update selected song
    yield put({
      type: SongActionTypes.GET_SONG_SUCCESS,
      payload: { ...selectedSong, payed: true },
    });
    // Update user data
    yield put(getUser(user.id));

    // 2. close modal
    yield callback();
    yield put(paymentComplete());

    gtag.event({
      category: 'payment',
      action: 'COMPLETE',
      label: songSlug,
    });
  } catch (error) {
    yield put(paymentError(error));
  }
}

export function* PaymentSagaWatcher() {
  yield takeLatest(PaymentActionTypes.START, paymentSaga);
}

export default PaymentSagaWatcher;
