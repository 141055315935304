import styled from 'styled-components';
import {
  position,
  space,
  margin,
  padding,
  SpaceProps,
  textAlign,
  TextAlignProps,
  maxWidth,
  MaxWidthProps,
} from 'styled-system';

type Props = SpaceProps & TextAlignProps & MaxWidthProps;

const Box = styled.div<Props>`
  ${position}
  ${space}
  ${margin}
  ${padding}
  ${textAlign}
  ${maxWidth}
`;

export default Box;
