import React from 'react';
import styled from 'styled-components';

const ListStyled = styled.div<{ space?: string }>`
  font-size: 18px;
  margin: ${({ space }) => (space ? space : '20px 0')};

  .list-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

const ListItem = styled.div<{ friend?: boolean }>`
  padding: 18px 0;
  opacity: ${(props) => (props.friend ? '0.55' : 1)};
  .list-item__title {
    font-size: 14px;
  }
  .list-item__description {
    font-size: 11px;
    color: #cccccc;
  }
  .list-item__time {
    font-size: 14px;
    color: #cccccc;
    padding-left: 15px;
    margin-left: auto;
  }
  .list-item__number {
    font-size: 14px;
    padding-right: 10px;
  }
`;

interface ListProps {
  label?: string;
  children: Object;
  space?: string;
}

const List = ({ label, children, space }: ListProps) => (
  <ListStyled space={space}>
    {label && <div className="menu-label">{label}</div>}
    {children}
  </ListStyled>
);

interface ListItemProps {
  title: string;
  description: string;
  friend?: boolean;
  number: number;
  time: string;
  onClick?: any;
}

List.Item = ({ title, description, friend, number, time, onClick }: ListItemProps) => {
  return (
    <ListItem className="list-item" onClick={onClick} friend={friend}>
      {number && <div className="list-item__number">{`${number}.`}</div>}
      <div>
        <div className="list-item__title">{title}</div>
        <div className="list-item__description">{description}</div>
      </div>
      {time && <div className="list-item__time">{time}</div>}
    </ListItem>
  );
};

export default List;
