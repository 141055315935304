import { LibraryState, LibraryActionTypes } from './types';

const INITIAL_STATE: LibraryState = {
  collection: [],
  loading: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case LibraryActionTypes.GET_LIBRARY_REQUEST:
      return { ...state, loading: true };
    case LibraryActionTypes.GET_LIBRARY_SUCCESS:
      return { ...state, collection: action.payload, loading: false };
    case LibraryActionTypes.GET_LIBRARY_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
