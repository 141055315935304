import React from 'react';
import styled from 'styled-components';

const ProgressStyled = styled.div`
  display: flex;
  align-items: center;
  .progress__label {
    font-size: 12px;
    font-weight: 700;
    width: 40px;
  }
`;

const ProgressLine = styled.div<{ progress: any }>`
  background-color: #f1f1f1;
  height: 5px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.progress}%;
    background: #566fff;
  }
`;

const Progress = ({ progress }: { progress: any }) => (
  <ProgressStyled>
    <div className="progress__label">{progress}%</div>
    <ProgressLine progress={progress} />
  </ProgressStyled>
);

export default Progress;
