export interface PaymentState {
  loading: boolean;
  error: any;
}

export enum PaymentActionTypes {
  START = '@@payment/START',
  COMPLETE = '@@payment/COMPLETE',
  ERROR = '@@payment/ERROR',
}

export type PaymentStartAction = {
  type: PaymentActionTypes.START;
  payload: PaymentStart & { callback: Function };
};

export type PaymentStart = {
  userId: string;
  songSlug: string;
};
