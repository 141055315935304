import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config/env';

/**
 * Create own axios instance.
 */
const instance = axios.create({
  // `baseURL` will be prepended to `url` unless `url` is absolute.
  // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
  // to methods of that instance.
  baseURL: API_URL,
});

/**
 * Middleware for success response.
 *
 * @param {Object} response
 * @returns
 */
const handleSuccess = (response: AxiosResponse) => response;

const handleError = (error: AxiosError) => {
  const status = error.response ? error.response.status : 0;

  switch (status) {
    case 401:
      // something here
      break;
    default:
      console.log(error);
      break;
  }

  return Promise.reject(error);
};

/**
 * Apply success and error middlewares.
 */
instance.interceptors.response.use(handleSuccess, handleError);

export default instance;

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `${token}`;
};

export const removeToken = () => {
  delete instance.defaults.headers.common.Authorization;
};
