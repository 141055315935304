import { SongType } from '../song/types';

export interface PlaylistState {
  loading: boolean;
  playlist: PlaylistType | null;
}

export interface PlaylistType {
  id: string;
  title: string;
  slug: string;
  description: string;
  price: number;
  'song-size': number;
  songs: SongType[];
  payed: boolean;
  imageSrc: string;
  fromLang: string;
  toLang: string;
}

export enum PlaylistActionTypes {
  GET_PLAYLIST_REQUEST = '@@playlist/GET_PLAYLIST_REQUEST',
  GET_PLAYLIST_SUCCESS = '@@playlist/GET_PLAYLIST_SUCCESS',
  GET_PLAYLIST_FAILURE = '@@playlist/GET_PLAYLIST_FAILURE',

  SUBSCRIBE_TO_PLAYLIST_REQUEST = '@@playlist/SUBSCRIBE_TO_PLAYLIST_REQUEST',
  SUBSCRIBE_TO_PLAYLIST_SUCCESS = '@@playlist/SUBSCRIBE_TO_PLAYLIST_SUCCESS',
  SUBSCRIBE_TO_PLAYLIST_FAILURE = '@@playlist/SUBSCRIBE_TO_PLAYLIST_FAILURE',
}
