import React, { useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  onClose: Function;
  opened: boolean;
  title?: string;
  onStateChange?(state: boolean): void;
};

const ModalComponent: React.FC<Props> = ({
  children,
  title = '',
  opened,
  className,
  onClose,
  onStateChange,
}) => {
  useEffect(() => {
    if (onStateChange) onStateChange(opened);
  }, [onStateChange, opened]);

  return (
    <div className={className}>
      <div className="modal-bottom">
        {title && (
          <div className="modal-bottom__header">
            <h3 className="modal-bottom__title">{title}</h3>
            <a
              href="/"
              className="modal-bottom__cancel"
              onClick={(event) => {
                event.preventDefault();
                onClose();
              }}
            >
              Cancel
            </a>
          </div>
        )}

        <div className="modal-bottom__content">{children}</div>
      </div>

      <div id="overlay" onClick={() => onClose()} />
    </div>
  );
};

const Modal = styled(ModalComponent)`
  .modal-bottom {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: -350px;
    height: 350px;
    z-index: 50;
    background: ${(props) => props.theme.bodyBackground};
    border-radius: 8px 8px 0 0;
    transition: all 0.15s ease-out;
    max-width: 700px;
    margin: 0 auto;
    visibility: hidden;

    ${(props) =>
      props.opened &&
      `opacity: 1;
        visibility: visible;
        bottom: 0;
      `}
  }
  .modal-bottom__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    /* Important to sync height with calc height in .modal-bottom__content  */
    height: 51px;
  }
  .modal-bottom__title {
    margin: 0;
  }
  .modal-bottom__content {
    padding: 18px 20px;
    overflow-x: scroll;
    height: calc(100% - 51px);
  }
  .modal-bottom__content p {
    margin-top: 0;
  }
  .modal-bottom__cancel {
    text-decoration: none;
  }
  #overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 1;
    transition: all 0.15s ease-out;
    ${(props) =>
      props.opened &&
      `opacity: 0.5;
        visibility: visible;`}
  }
`;

export default Modal;
