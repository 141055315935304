import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../modules/playlist/actions';
import Template from '../../containers/Template';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Cards from '../../components/Cards';
import List from '../../components/List';
import Button from '../../components/Button';
import Box from '../../components/styled/Box';
import { PlaylistState } from '../../modules/playlist/types';
import { AppState } from '../../redux/store';
import { match } from 'react-router';
import { AuthState } from '../../modules/auth/types';

// @ts-ignore
const { Card } = Cards;

interface Props {
  app: any;
  changeTheme: Function;
  playlist: PlaylistState;
  auth: AuthState;
  query: { id: string };
  match: match<{ id: string }>;
  getPlaylist: typeof actions.getPlaylist;
  subscribeToPlaylist: typeof actions.subscribeToPlaylist;
}

const FixedButton = styled(Button)<{ success: boolean }>`
  position: fixed;
  width: calc(100% - 60px);
  left: 30px;
  right: 30px;
  bottom: 25px;
  transition: background-color 0.15s ease-in;
  &:disabled {
    background: #e1e1e1;
    color: #a1a1a1;
  }
  ${(props) =>
    props.success &&
    `
    background-color: #6cc35d !important;
    color: #fff!important;
  `}
`;

const descriptionFallback = 'Empty description';

class Playlist extends Component<Props> {
  state = {
    subscribed: false,
  };

  componentDidMount() {
    const { getPlaylist, match } = this.props;
    getPlaylist({ slug: match.params.id });
  }

  subscribe = () => {
    const { match, subscribeToPlaylist, auth } = this.props;
    if (auth.user && auth.user.id) {
      subscribeToPlaylist({
        userId: auth.user.id,
        slug: match.params.id,
      });
      this.setState({ subscribed: true });
    }
  };

  render() {
    const {
      changeTheme,
      app: { theme },
      auth,
      playlist: { playlist, loading },
    } = this.props;
    const { subscribed } = this.state;

    return (
      <Template changeTheme={changeTheme}>
        {playlist && !loading && (
          <Box pb="75px">
            <Box pb="20px">
              <Card
                title={playlist.title}
                status="available"
                theme={theme}
                imageSrc={playlist.imageSrc}
                fromLang={playlist.fromLang}
                toLang={playlist.toLang}
              />
              <Box>
                <Title description="Perhaps this will interest you">Description</Title>
                <Text>{playlist.description || descriptionFallback}</Text>
              </Box>
            </Box>
            <Box>
              <Title description={`${playlist['song-size']} Songs`}>Collection</Title>
              <List space="0">
                {playlist.songs.map((song, index) => (
                  // @ts-ignore
                  <List.Item
                    key={song.hash}
                    title={song.title}
                    description={song.performer}
                    number={index + 1}
                  />
                ))}
              </List>
            </Box>
            <FixedButton
              onClick={this.subscribe}
              disabled={!auth.user || !auth.token || subscribed}
              success={subscribed}
            >
              {auth.token
                ? !subscribed
                  ? 'Tap to Start Playlist'
                  : 'Check message in Telegram'
                : "You're not authorized"}
            </FixedButton>
          </Box>
        )}
        {loading && <div>Loading...</div>}
      </Template>
    );
  }
}

const connector = connect(
  ({ auth, app, playlist }: AppState) => ({ app, auth, playlist }),
  // @ts-ignore
  (dispatch) => bindActionCreators(actions, dispatch)
);

export default connector(Playlist);
