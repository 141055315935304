import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

// Modules
import app from '../modules/app/reducer';
import auth from '../modules/auth/reducer';
import library from '../modules/library/reducer';
import playlist from '../modules/playlist/reducer';
import song from '../modules/song/reducer';
import payment from '../modules/payment/reducer';

// Interfaces
const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    library,
    playlist,
    song,
    payment,
  });

export default rootReducer;
