//
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { UserType } from '../../modules/auth/types';

const sentry = {
  blacklistResponseStatusCapture: [401, 404],

  init({ SENTRY_DSN, environment }: { SENTRY_DSN: string; environment: string }) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment,
    });
  },

  setUser({ id }: { id: string }) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id });
    });
  },

  setExtraContext: (context: Object) => {
    Sentry.configureScope((scope) => {
      // @ts-ignore
      scope.setExtra(...Object.entries(context)[0]);
    });
  },

  captureException: (error: Object) => {
    Sentry.captureException(error);
  },

  errorResponseCapture: (error: AxiosError): void => {
    if (!error) return;

    if (
      error.response &&
      error.response.status >= 400 &&
      !sentry.blacklistResponseStatusCapture.includes(error.response.status)
    ) {
      Sentry.withScope((scope) => {
        scope.setExtra('network', error);
        Sentry.captureException(error);
      });
    }
    return;
  },

  fill: (user: UserType) => {
    sentry.setUser({
      id: user.id,
    });
  },
};

export default sentry;
