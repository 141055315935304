import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import Modal from '../Modal';
import Button from '../../components/Button';
import FloatButtonContainer from '../../components/FloatButtonContainer';
import { Flex } from '../../components/styled';
import { event } from '../../lib/gtag';
import { useDispatch, useSelector } from 'react-redux';
import { payment } from '../../modules/payment/actions';
import { AppState } from '../../redux/store';
import * as gtag from '../../lib/gtag';

type Props = {
  lyrics: any;
  performer: string;
  title: string;
  tabView: string;
  theme: string;
  changeTheme: Function;
  currentNote: string;
  onTabHandle: Function;
  onNoteHandle: Function;
  activeLineId: string;
  payed: boolean;
  explanationStateChange: Function;
  onSuccess: Function;
  guest: boolean;
  price?: string;
};

const SongStyled = styled.div`
  .header-song__author {
    font-size: 20px;
    font-weight: 700;
  }
  .header-song__title {
    padding-top: 5px;
    color: #ccc;
  }
  .tabs {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  .tabs__item {
    flex: 1 0 auto;
    text-align: center;
  }
  .tabs__link {
    position: relative;
    display: block;
    padding: 10px 0;
    fill: #ccc;
    text-decoration: none;
    color: #ccc;
  }
  .tabs__icon {
    width: 30px;
  }
  .tabs__link_active {
    color: #434343;
    font-weight: 700;
  }
  .tabs__link_active:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #566fff;
    border-radius: 15px;
    margin: 0 10px;
  }
  .tabs__image {
    height: 19px;
  }
  .alternation .songs-item {
    flex-direction: column;
    display: block;
  }
  .alternation .songs-item__line:first-child {
    padding-bottom: 0;
  }
  .alternation .songs-item__line:last-child {
    padding-top: 0;
  }
  .change .songs-item__line_translate {
    display: none;
  }
  .change .songs-item_revert {
    background-color: ${(props) => props.theme.textLineInfo};
  }
  .change .songs-item_revert .songs-item__line_original {
    display: none;
  }
  .change .songs-item_revert .songs-item__line_translate {
    display: block;
  }
  .songs__main {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 175px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
  .songs-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .information-icon {
    width: 15px;
    height: 15px;
  }
  .songs-item-icon {
    display: none;
  }
  .change .songs-item-icon {
    background: ${(props) => props.theme.borderColor};
    border-left: 1px solid ${(props) => props.theme.borderColor};
    align-self: stretch;
    line-height: 1em;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .songs-item_info {
    background-color: ${(props) => props.theme.textLineInfo};
  }
  .songs__line {
    margin-bottom: 25px;
  }
  .songs__line .songs-item:last-child {
    border-bottom: none;
  }
  .songs__main .songs__line:last-child {
    margin-bottom: 0;
  }
  .songs-item__line {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    min-height: 1px;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .song-item-container {
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  .header-song__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px 20px;
  }
  .theme-icon {
    fill: ${(props) => props.theme.textColor};
  }
`;

const HighlightLine = styled.span<{ active: boolean }>`
  display: inline-block;
  padding: 0px 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? props.theme.colorPrimary : 'none')};
  color: ${(props) => (props.active ? '#fff' : props.theme.textColor)};
  transition: all 0.15s ease-out;
`;

export const Song: React.FC<Props> = React.memo(
  ({
    performer,
    title,
    payed,
    tabView,
    currentNote,
    onNoteHandle,
    lyrics,
    theme,
    changeTheme,
    activeLineId,
    explanationStateChange = () => {},
    onSuccess,
    guest,
    price,
    onTabHandle,
  }) => {
    const { user, song, payment: paymentState } = useSelector((state: AppState) => ({
      user: state.auth.user,
      song: state.song.selected,
      payment: state.payment,
    }));
    const dispatch = useDispatch();

    const [revertIndex, setRevertIndex] = React.useState<string | null>(null);
    const [additionalContentOpened, setAdditionalContentOpened] = React.useState(false);

    const tabHandle = (view: any) => (event: any) => {
      if (event) event.preventDefault();
      // Reset everything before tab will change.
      setRevertIndex(null);
      // Fire event.
      onTabHandle(view);
    };

    const additionalContentToggle = () => setAdditionalContentOpened(!additionalContentOpened);

    const toggleSpecificLineLanguage = ({ stanzaIndex, lineIndex }: any) => {
      setRevertIndex(
        revertIndex !== `${stanzaIndex}${lineIndex}` ? `${stanzaIndex}${lineIndex}` : null
      );
    };

    const paymentHandler = () => {
      dispatch(
        payment({
          userId: user.id,
          songSlug: user.library[song.playList].slug,
          callback: () => setAdditionalContentOpened(false),
        })
      );
    };

    return (
      <SongStyled>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{ display: 'none' }}>
          <symbol id="tablet" viewBox="0 0 52 52">
            <path d="M26 0C11.663 0 0 11.663 0 26s11.663 26 26 26 26-11.663 26-26S40.337 0 26 0zm0 50C12.767 50 2 39.233 2 26S12.767 2 26 2s24 10.767 24 24-10.767 24-24 24z" />
            <path d="M26 37a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1zM26.113 9.001L25.998 9a7.938 7.938 0 0 0-5.615 2.304A7.944 7.944 0 0 0 18 17a1 1 0 1 0 2 0c0-1.618.635-3.136 1.787-4.272 1.153-1.137 2.688-1.765 4.299-1.727 3.161.044 5.869 2.752 5.913 5.913a5.973 5.973 0 0 1-2.751 5.132C26.588 23.762 25 26.794 25 30.158V33a1 1 0 1 0 2 0v-2.842c0-2.642 1.276-5.105 3.332-6.432a7.96 7.96 0 0 0 3.667-6.84c-.06-4.287-3.598-7.825-7.886-7.885z" />
          </symbol>
        </svg>
        <div className="header-song__container">
          <div className="header-song">
            <div className="header-song__author">{performer}</div>
            <div className="header-song__title">{title}</div>
          </div>
          <div className="header-song__theme" onClick={() => changeTheme()}>
            {theme === 'default' ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="theme-icon" width="24" height="24">
                <path d="M14 19h-4a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm0 2h-4a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218L14.25 23zM18 9.201c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867C15.279 11.808 16 10.556 16 9.201c0-2.592-2.075-3.772-4.003-3.772C10.072 5.429 8 6.609 8 9.201c0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867H9.214C9.214 15.184 6 12.77 6 9.201c0-3.723 2.998-5.772 5.997-5.772C14.998 3.429 18 5.48 18 9.201zm4-.691v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69H22zM11.352 1.957V0h1.371v1.964a7.73 7.73 0 0 0-1.371-.007zM7.609 3.251l-1.04-1.94L7.777.663l1.037 1.933a7.77 7.77 0 0 0-1.205.655zm10.586 1.735l1.942-1.394.799 1.115-2.054 1.473a6.697 6.697 0 0 0-.687-1.194zm-3.01-2.389L16.223.663l1.208.648-1.041 1.941a7.763 7.763 0 0 0-1.205-.655zM5.117 6.18L3.063 4.708l.799-1.115 1.942 1.393a6.688 6.688 0 0 0-.687 1.194zm13.707 6.223l2.354.954-.514 1.271-2.425-.982c.21-.397.408-.812.585-1.243zM5.716 13.558l-2.356 1.06-.562-1.251 2.34-1.052c.173.433.371.845.578 1.243zM4.538 9.882H2V8.51h2.535a8.612 8.612 0 0 0-.035.691c0 .233.018.458.038.681z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="theme-icon" width="24" height="24">
                <path d="M19 6.734c0 4.164-3.75 6.98-3.75 10.266h-1.992c.001-2.079.997-3.826 1.968-5.513C16.138 9.902 17 8.404 17 6.734 17 3.626 14.483 2 11.996 2 9.513 2 7 3.626 7 6.734c0 1.67.862 3.168 1.774 4.753.971 1.687 1.966 3.434 1.967 5.513H8.75C8.75 13.714 5 10.897 5 6.734 5 2.391 8.498 0 11.996 0 15.498 0 19 2.394 19 6.734zM15 18.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5zm0 2a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5zm-1.701 3.159c-.19.216-.465.341-.753.341h-1.093c-.288 0-.562-.125-.752-.341L9.25 22h5.5l-1.451 1.659z" />
              </svg>
            )}
          </div>
        </div>
        <ul className="tabs">
          <li className="tabs__item" onClick={tabHandle('parallel')}>
            <a
              href="/"
              className={classnames('tabs__link', {
                tabs__link_active: tabView === 'parallel',
              })}
              data-view="parallel"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwAAAAD6CAMAAAAhvtBeAAAAWlBMVEUAAADv7+9IcP/v7+9IcP9IcP9IcP9IcP/v7+9IcP/v7+9IcP/v7+9IcP/v7+/v7+/v7+/v7+9IcP9IcP/v7+/v7+/v7+9IcP/v7+/v7+/v7+9IcP9IcP/v7+8+jOddAAAAHHRSTlMABeHhVKoG+vrarImJW9tbVVQD8/PYppSUXFEHXxun5gAABA5JREFUeNrs2UlOAzEQQNHqkcwkASIRue9/TbZZJG4jWFjye2eoryqpAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgr+Zx6t8SDz620zgHTRi3iSe2Y/xKd74Nm4UHn1+3cxd1u+4TL+yvUe5+WnjidI+qmf+MfZTqDgsvHGpeAmMiY4xC5j/jENWa+0RGP0eRy0LGJWp1TGQdo0Q3LGQM1R5BUyJrihLvC1nfUSkX0Io+SuwWsnZRKf+vFW9RwgW0YohKJVZECf+vFZuoVOI/AlhYEZVKCOAJASAAASAAATROAAJomgAE0DQBCKBpAhBA0wQggKYJQABNE4AAmiYAATRNAAJo2g9797bbKAyFYXSHENLmnPQ4Hfz+rznS3KUKxpVy4cZrPQMfWCDxC0AATROAAJomAAE0TQACaJoABNA0AQigaQIQQNMEIICmCUAATROAAJomAAE0TQACaJoABNA0AQigaQIQQNME8NABvIxkrQXw0AGYCL7LQMZr4nf+Ht1E0l0mkt4Sv3Mgw0jejL2JpIeeSDKTmtf9NZL30CN5hrLz+jCT+tAzqRHbkUnbKHRKZJyiXpePkQkflyi1S0zaRdV670Jveu6j3OIzMeGz4gPQf8N+1RkMvrLuVvshfuR8SNxwOAdNWBw3S4PBV56Wm2Ptt38AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA7mTo7QR/8/K86oegCZbic0vx5RandzvB117f3k+17wRftiMTtpco92Up/qbDV1TN9Z+xjVKLXWLCruaHQD+S0Uch13/GLqo1dCMZ3RBFzomMc9RqP5K1jxKLZSJjWe0haDWStYoSx0TWn6iUE9CMLkpsElmbqJTvXzPWUcIJaMYyKjUyI0r4/jXjKSo1co8AEjOiUiMCuEEACEAACOAfe3eXmjAQQGF0jFFbNf61RaHJ/rdZ6Jtg0hR8iLnn7OEbhnmYK4BwAhBANAEIIJoABBBNAAKIJgABRBOAAKIJQADRBCCAaAIQQDQBCCCaAAQQTQACiCYAAUQTgACiCUAA0QQggGgCEEA0AQggmgAEEE0AAogmAAFEE4AAoglAANEEIIBoAph1AB8tgzYCmHUAJoKfMpDx2fGa36ObSHrKRNJXx2sOZBjJ+0NjImnWE0lmUodV30byZj2SZyh7WF3MpM56JrWUXUuvXRnp1DHgVKbrcm3pcb2UsfYdvfZl0mpvoQ+912W8xa2jx23CF6Bf62ZVGQy+s6lWzbr8y/nQ8cDhXIiwOG6XBoPvvC23x6kf/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP+3BIQEAAACAoP+vvWEAAAAAAAAAAACAvwCeig2GefS/xAAAAABJRU5ErkJggg=="
                alt=""
                className="tabs__image"
              />
            </a>
          </li>
          <li className="tabs__item" onClick={tabHandle('alternation')}>
            <a
              href="/"
              className={classnames('tabs__link', {
                tabs__link_active: tabView === 'alternation',
              })}
              data-view="alternation"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwAAAAD6CAMAAAAhvtBeAAAAVFBMVEUAAABIcP/v7+9IcP/v7+/v7+9IcP/v7++gsvdIcP9IcP9IcP/v7+9IcP/v7+9IcP/v7+/v7+9IcP/v7+/v7+/v7+9IcP/v7+9IcP9IcP9IcP/v7+8G2lE4AAAAGnRSTlMA4eFUVPiqBgMG+omJW1vb26zz2KumlJTXBzQF7MwAAAKSSURBVHja7NnLCcNADEBBLQuLPxhDTkH9F5oGcktkdJipQQ8kFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAv9rnGkfCv73ea+7R3bwTqtwzWtvOhErnFo2Zf6qd0ddMqNZ3C9pHQrXR9hK+Eupd0dRKqLeiKRsQTxjRlP8XTziiqYQnRFMJXwgABAACAAGAAEAAIAAQAAgABAACAAGAAPiwdy+5DcJQGEYvFAJpSJo+Eql4//vsoFM6i9FtfM4a+CQDkn8EAAIAAYAAaFsktcIGAYAAQAAgABAACAAEAAIAAYAA4J8G8LHChlauRzcRzKZWBjJMJLGtkYkkI3n8pYmRPDOp7KD7jqwMZbOpkaHsiNMKdZ0isfm+Qk33OVJ78S2Uet4yn39+Hc+HzmAwj/faHc7HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAntg03PqxwKONn7dhiuyGa4FarkOkNi8FalrmSMzzT21L5DUUqC3vKWjqC9TWp30TvhSo7xJJfRWo7z2ScgJiD30k5f8XexgjqQJ7iKQKbBAACAB+2LtjFIZhIIqCwiTEhTE2KVzs/e+ZIq3UWbBmZ+7wQEKgLwAQAAgABAACAAGAAEAAIAAQAAgABAACAAGAAKitJRXQIQAQAAgABAACAAGAAEAAIAAQADw0AN+j01Xle/RvQEeVgQwTSQzUmEgyksdAjZE8M6n0FZlJNZTNQI2h7Na2gLm2lth6Bcx0rS211xEwy5H5/PP32c/Fixj3ey/nnvf+CwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwaw8OSAAAAAAE/X/djkAFAAAAAAAAAAAuAgC+33gcYdamAAAAAElFTkSuQmCC"
                alt=""
                className="tabs__image"
              />
            </a>
          </li>
          <li className="tabs__item" onClick={tabHandle('change')}>
            <a
              href="/"
              className={classnames('tabs__link', {
                tabs__link_active: tabView === 'change',
              })}
              data-view="change"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwAAAAD6CAMAAAAhvtBeAAAAVFBMVEUAAABIcP/v7+9IcP/v7+/v7+9IcP/v7++gsvdIcP9IcP9IcP/v7+9IcP/v7+9IcP/v7+/v7+9IcP/v7+/v7+/v7+9IcP/v7+9IcP9IcP9IcP/v7+8G2lE4AAAAGnRSTlMA4eFUVPiqBgMG+omJW1vb26zz2KumlJTXBzQF7MwAAAK7SURBVHja7NnBCcNADEXBbxZMHBMCOYXtv9AUoVyEZmrQAwkFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAKDqWufx3DTy+Z7rCn+x3puG3ivUPe5NU/cjVJn/xu5QtDaN2YKKrmPT2OESrnltWnuFinPT2hkqbEDNHaHC/6u5Z6jYNBcEMFkQwGRBAJMFAUwWBDBZEMBkQQCTBQFMFgQwWRDAZEEAkwUBTBYEMFkQwI+9O8tNGAiiKPogBkwYQgaQQu9/n9lCpOanVOeswVdq21K/zoIAOgsC6CwIoLMggM6CADoLAugsCKCzIIDOggA6CwLoLAigsyCAzoIAOgsC6CwIoLMw4+tJaa5Hn2MiuDgDGSaSWjORZCSvNSN5ZlI72/wGQ9l9GcqednpS1inMWh9PinqsYd6bb6ElfTj/vMjxst8YDC7lfbO/HAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMC/HJb7djcoZPd9Xw7hJZbboKDbEuat50FR5zXM8vwXdg6TlkFhTkGTDttBYVtvwnOug9KuYcbPoLTPMMMJqLhtmOH/V3G7MGNQXBBAZ0EAnQUBdPbH3h2bMAwDQBQ9TEJcGGOTwoX23zNDKI2493b4ICHQBQE0CwJoFgTQLAigWRBAsyCAZkEAzYIAmgUBNAsCaBYE0CwIoFkQQLMggGZBAM2CAJoFATQLAmgWBNAsCKBZEECzIIBmQQDNggCaBQE0CwJoFnyPXsz36HO+g6UZyDCRVM1EkpG8akbyzKQ2M5NqKLuaoexpx2BZR5i1P4NFPXuY97oGC7qcf/7kc96bF7GlvLf7dP8FAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPi1B4cEAAAAAIL+v3aEFQAAAAAAAAAAAK4AlYAeaPN3xQcAAAAASUVORK5CYII="
                alt=""
                className="tabs__image"
              />
            </a>
          </li>
        </ul>
        <div className="songs__main">
          <div className={tabView}>
            {lyrics.stanzas.map((stanza: any, stanzaIndex: any) => (
              <div key={stanzaIndex} className="songs__line">
                {stanza.lines.map((line: any, lineIndex: any) => (
                  <div key={lineIndex} className="song-item-container">
                    <div
                      className={classnames('songs-item', {
                        'songs-item_info': line.note && payed,
                        'songs-item_revert': revertIndex === `${stanzaIndex}${lineIndex}`,
                      })}
                      onClick={() => {
                        if (tabView !== 'change') onNoteHandle(line.note);

                        toggleSpecificLineLanguage({
                          stanzaIndex,
                          lineIndex,
                        });
                      }}
                    >
                      <div className="songs-item__line songs-item__line_original">
                        {/* Karaoke mode */}
                        {payed ? (
                          <HighlightLine active={activeLineId === `${stanzaIndex}${lineIndex}`}>
                            {line.text.en}
                          </HighlightLine>
                        ) : (
                          line.text.en
                        )}
                      </div>
                      <div className="songs-item__line songs-item__line_translate">
                        {/* Karaoke mode */}
                        {payed ? (
                          <HighlightLine active={activeLineId === `${stanzaIndex}${lineIndex}`}>
                            {line.text.ru}
                          </HighlightLine>
                        ) : (
                          line.text.ru
                        )}
                      </div>
                    </div>

                    {line.note && (
                      <div className="songs-item-icon" onClick={() => onNoteHandle(line.note)}>
                        <svg className="information-icon">
                          <use xlinkHref="#tablet" />
                        </svg>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <Flex justifyContent="center">
              <Button
                onClick={() => {
                  event({
                    category: 'Song',
                    action: 'complete',
                  });
                  onSuccess();
                }}
              >
                {guest ? 'Хочу получать песню каждый день' : 'Я все понял, поехали дальше'}
              </Button>
            </Flex>
          </div>
        </div>
        {/* Trial */}
        {!payed && !guest && (
          <FloatButtonContainer>
            <Button
              onClick={() => {
                additionalContentToggle();
                gtag.event({
                  category: 'payment',
                  action: 'Open Modal',
                  label: 'Attempt to pay',
                });
              }}
            >
              Открыть полный доступ
            </Button>
          </FloatButtonContainer>
        )}
        {payed && (
          <Modal
            title="Explanation"
            onClose={onNoteHandle}
            opened={Boolean(currentNote)}
            // @ts-ignore
            onStateChange={explanationStateChange}
          >
            {currentNote}
          </Modal>
        )}
        {!payed && !guest && (
          <Modal
            title="Открыть доступ к плейлисту"
            onClose={additionalContentToggle}
            opened={additionalContentOpened}
          >
            <Flex flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
              <p>
                Готов продолжить совершенствовать язык? <span role="img" aria-label="smart man">🤓</span>
                <br />
                Получи полный доступ ко всему плейлисту с полным функционалом всего за {price} рублей навсегда!
              </p>
              <ul style={{ paddingLeft: 16, margin: 0 }}>
                <li>
                  Смотри видео в режиме караоке с синхронизированной строкой.
                </li>
                <li>Изучай языковые нюансы с комментариями преподавателей.</li>
                <li>Поддержи проект, его разработчиков и преподавателей.</li>
              </ul>
              <Flex justifyContent="center" mt="20px">
                <Button onClick={() => paymentHandler()} disabled={paymentState.loading}>
                  Открыть плейлист за {price} ₽
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )}
      </SongStyled>
    );
  }
);
