import { PlaylistState, PlaylistActionTypes } from './types';

const INITIAL_STATE: PlaylistState = {
  playlist: null,
  loading: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case PlaylistActionTypes.GET_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case PlaylistActionTypes.GET_PLAYLIST_SUCCESS:
      return { ...state, playlist: action.payload, loading: false };
    case PlaylistActionTypes.GET_PLAYLIST_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
