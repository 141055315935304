import request from './request';
import { PaymentStart } from '../modules/payment/types';

export default {
  async getUser(id: string) {
    const response = await request.get(`/users/${id}`);
    return response.data;
  },

  async subscribeByEmail(email: string) {
    const response = await request.post(`/users/new-mail`, { email });
    return response.data;
  },

  async updateUserSettings(id: string, params: { introVisited: boolean }) {
    const response = await request.patch(`/users/${id}/settings`, params);
    return response.data;
  },

  async getLibrary() {
    const response = await request.get('/play-lists/');
    return response.data;
  },

  async getPlaylist(slug: string) {
    const response = await request.get(`/play-lists/${slug}`);
    return response.data;
  },

  async getSong({ playlistId, songId }: { playlistId: string; songId: string }) {
    const response = await request.get(`/play-lists/${playlistId}/${songId}`);
    return response.data;
  },

  async subscribeToPlaylist(userId: string, slug: string) {
    const response = await request.post(`/users/${userId}/subscribe/${slug}`);
    return response.data;
  },

  async payment({ userId, songSlug }: PaymentStart) {
    const response = await request.post(`/users/${userId}/pay/${songSlug}`);
    return response.data;
  },
};
