import React, { FC } from 'react';
import styled from 'styled-components';
import Template from '../../containers/Template';
import { Box } from '../../components/styled';
import { Helmet } from 'react-helmet';

const PartnershipComponent: FC<{ className: string }> = ({ className }) => {
  return (
    <div className={className}>
      <Helmet>
        <title>Daily Song Bot | Сотрудничество</title>
        <meta name="Description" content="Знаешь английский и любишь музыку? - приходи к нам!" />
      </Helmet>
      <Template>
        <h1>Сотрудничество</h1>
        <Box>
          <p>
            Если Вы преподаете иностранные языки, любите музыку, хотите заинтересовать учеников или
            найти новых, то присоединяйтесь!
          </p>

          <p>Мы небольшая, но очень мотивированная команда которая ищет сотрудничество:</p>

          <p>
            <ul>
              <li>С преподавателями иностранных языков;</li>
              <li>Со Школами иностранных языков;</li>
            </ul>
          </p>

          <h2>Как это работает</h2>

          <p>
            Пользователь выбирает себе один плейлист (набор ~ 10 песен) и подписывается на него.
            После этого каждый день бот отправляет пользователю одну песню. Песня содержит в себе,
            саму песню, текст, перевод и комментарии преподавателя к строкам{' '}
            <a
              href="tg://resolve?domain=daySongBot&start=f-163389677_s-2004355343"
              target="_blank"
              rel="noopener noreferrer"
            >
              песни
            </a>
            .
          </p>
          <p>
            Вы, можете <b>создать свой собственный плейлист</b> из песен, которые нравятся вам. Бот
            расскажет про этот плейлист всем своим пользователям (~1600 чел). В описании плейлиста
            будут Ваши контакты, рассказ о себе.
          </p>

          <h2>Зачем мне это нужно?</h2>

          <p>
            <ul>
              <li>
                Рассказать бесплатно о себе или своей школе на всю аудиторию бота - это люди,
                которые изучают английский прямо сейчас.
              </li>
              <li>
                Мотивировать своих учеников на ежедневные интересные тренировки, подобрав песни,
                которые нужны именно вашей группе.
              </li>
            </ul>
          </p>

          <h2>Как стать автором плейлиста?</h2>

          <p>
            Вам достаточно написать нам в{' '}
            <a href="http://t.me/forwardomg" target="_blank" rel="noopener noreferrer">
              Telegram
            </a>{' '}
            или на <a href="mailto:forwardomg@gmail.com">почту forwardomg@gmail.com</a>.
          </p>
        </Box>
      </Template>
    </div>
  );
};

const Partnership = styled(PartnershipComponent)`
  h1 {
    margin-bottom: 25px;
  }
  h2 {
    margin-top: 35px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 27px;
  }
`;

export default Partnership;
