import styled from 'styled-components';

const Tag = styled.div<{ textColor: string }>`
  display: inline-block;
  background-color: ${(props) => props.color || '#eee'};
  color: ${(props) => props.textColor || props.theme.textColor};
  padding: 0 12px;
  font-size: 10px;
  border-radius: 5px;
  line-height: 18px;
  margin-right: 5px;
`;

export default Tag;
