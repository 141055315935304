import { put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { SongActionTypes } from './types';

function* getSongSaga(action: any) {
  try {
    const data = yield api.getSong(action.payload);
    yield put({
      type: SongActionTypes.GET_SONG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: SongActionTypes.GET_SONG_FAILURE });
  }
}

export function* SongSagaWatcher() {
  yield takeLatest(SongActionTypes.GET_SONG_REQUEST, getSongSaga);
}

export default SongSagaWatcher;
