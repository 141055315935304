import React, { Component } from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';
import Progress from './Progress';
import ActionIcons from './ActionIcons';
import { Flex } from '../styled';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const VideoContainer = styled.div<{ visible?: boolean }>`
  position: absolute;
  right: 10px;
  bottom: 65px;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;
  ${(props) =>
    !props.visible &&
    `
    display: none;
  `}
`;

const Actions = styled.div`
  padding: 15px 10px;
  background-color: ${(props) => props.theme.bodyBackground};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  line-height: 0;
`;

const ActionsContainer = styled.div<{ ready?: boolean }>`
  opacity: ${(props) => (props.ready ? '1' : '0.2')};
  pointer-events: ${(props) => (props.ready ? 'auto' : 'none')};
  transition: opacity 0.15s ease-in;
`;

export const Icon = styled.div`
  display: inline-block;
  padding: 0 8px;
`;

export const IconSvg = styled.svg`
  width: 25px;
  height: 25px;
`;

const IconSmallSvg = styled.svg`
  width: 20px;
  height: 20px;
`;

const options = {
  height: '90',
  width: '150',
  playerVars: {
    modestbranding: 1,
    disablekb: 1,
    playsinline: 1,
    iv_load_policy: 3,
    rel: 0,
    controls: 0,
    showinfo: 0,

    // Captions are flaky on YouTube
    cc_load_policy: 0,
  },
};

interface PlayerYoutube {
  id: string;
  timeChanged: Function;
  visible: boolean;
  onManualPlayStateChange(state: string): void;
}

type State = {
  player: any;
};

let timerId: any;

class PlayerYoutube extends Component<PlayerYoutube, State> {
  state = {
    player: null,
    play: false,
    progress: 0,
    // -1 – воспроизведение видео не началось
    // 0 – воспроизведение видео завершено
    // 1 – воспроизведение
    // 2 – пауза
    // 3 – буферизация
    // 5 – видео находится в очереди
    videoState: null,
  };

  static defaultProps = {
    onManualPlayStateChange: () => {},
  };

  onReady = (event: Event) => {
    this.setState(
      {
        player: event.target,
      },
      () => {
        this.playVideo();
      }
    );
  };

  replayVideo = () => {
    const { player } = this.state;
    // @ts-ignore
    if (player) player.seekTo(0);
  };

  playVideo = () => {
    const { player } = this.state;
    // @ts-ignore
    if (player) player.playVideo();
  };

  pauseVideo = () => {
    const { player } = this.state;
    // @ts-ignore
    if (player) player.pauseVideo();
  };

  moveTo = (time: number) => () => {
    const { player } = this.state;
    if (player) {
      // @ts-ignore
      const currentTime = player.getCurrentTime();
      // @ts-ignore
      player.seekTo(currentTime + time);
    }
  };

  changeState = (play: boolean) => () => {
    // @ts-ignore
    this.setState({ play });
  };

  actionSwitcher = (actionId: number) => {
    const { onManualPlayStateChange } = this.props;

    switch (actionId) {
      case 0:
        this.replayVideo();
        break;
      case 1:
        this.pauseVideo();
        onManualPlayStateChange('pause');
        break;
      case -1:
      case 2:
      case 3:
      case 5:
        this.playVideo();
        onManualPlayStateChange('play');
        break;

      default:
        break;
    }
  };

  render() {
    const { id, timeChanged, visible } = this.props;
    const { player, progress } = this.state;
    const that = this;

    return (
      <Container className="player">
        <VideoContainer visible={visible}>
          <YouTube
            videoId={id.trim()}
            // @ts-ignore
            opts={options}
            onReady={this.onReady}
            onPlay={this.changeState(true)}
            onPause={this.changeState(false)}
            onStateChange={(event) => {
              if (this.state.videoState !== event.data)
                // @ts-ignore
                this.setState({ videoState: event.data });

              if (event.data === 1) {
                const playerTotalTime = event.target.getDuration();

                timerId = setInterval(function () {
                  const playerCurrentTime = event.target.getCurrentTime();
                  const playerTimeDifference = (playerCurrentTime / playerTotalTime) * 100;
                  const playerTimePercent = Math.round(playerTimeDifference);
                  // @ts-ignore
                  that.setState({ progress: playerTimePercent });
                  timeChanged(Math.round(playerCurrentTime * 1000)); //to ms
                }, 250);
              } else {
                clearInterval(timerId);
              }
            }}
          />
        </VideoContainer>
        <Actions>
          <ActionsContainer ready={Boolean(player)}>
            <Flex justifyContent="center" alignItems="center">
              <Icon onClick={this.moveTo(-5)}>
                <IconSmallSvg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 446.25 446.25"
                >
                  <path
                    d="M216.75 376.125v-306L0 223.125l216.75 153zm12.75-153l216.75 153v-306l-216.75 153z"
                    fill="#566fff"
                  />
                </IconSmallSvg>
              </Icon>

              <ActionIcons videoState={this.state.videoState} onClick={this.actionSwitcher} />

              <Icon onClick={this.moveTo(5)}>
                <IconSmallSvg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 446.25 446.25"
                >
                  <path
                    d="M0 376.125l216.75-153L0 70.125v306zm229.5-306v306l216.75-153-216.75-153z"
                    fill="#566fff"
                  />
                </IconSmallSvg>
              </Icon>
            </Flex>
          </ActionsContainer>
        </Actions>

        <Progress value={progress} />
      </Container>
    );
  }
}

export default PlayerYoutube;
