import styled from 'styled-components';

const Button = styled.button`
  background: #566fff;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  border: none;
  padding: 16px 25px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  outline: none;
  cursor: pointer;

  ${(props) =>
    props.theme === 'success' &&
    `
    background: #52c41a;
  `}
`;

export const ButtonLink = Button.withComponent('a');

export default Button;
