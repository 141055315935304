import styled from 'styled-components';
import {
  fontSize,
  FontSizeProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

type Props = FontSizeProps | LineHeightProps | TextAlignProps;

const Text = styled.p<Props>`
  font-size: 14px;
  line-height: 24px;
  ${fontSize}
  ${textAlign}
  ${lineHeight}
`;

export default Text;
