// @flow
import React from 'react';

const Promo = () => {
  return (
    <div>
      <style>{`
        .promo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100vh;
          margin-top: -60px;
        }
        .promo__title {
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .promo__description {
          font-size: 14px;
          line-height: 1.6em;
          max-width: 230px;
          margin-bottom: 25px;
        }
        .promo__image {
          border-radius: 50%;
          width: 100px;
        }
        .promo__link {
          display: inline-block;
          color: #fff;
          border-radius: 25px;
          padding: 10px 25px;
          font-size: 16px;
          background-color: #566fff;
          text-decoration: none;
        }
        .promo__description-bottom {
          margin: 15px 0;
          font-size: 12px;
          color: #ccc;
          max-width: 180px;
          line-height: 1.5em;
        }
      `}</style>

      <div id="promo" className="promo">
        <div className="promo__avatar">
          <img src="https://i.imgur.com/bLZsDH5.jpg" className="promo__image" alt="Logo" />
        </div>
        <h2 className="promo__title">Daily Song Bot</h2>
        <div className="promo__description">
          Every morning Ding-Dong will send you a song that you will have to master.
        </div>
        <a href="tg://resolve?domain=daySongBot" className="promo__link">
          Open Bot
        </a>
        <div className="promo__description-bottom">
          You need to have a telegram client to open the link.
        </div>
      </div>
    </div>
  );
};

export default Promo;
