import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Template from '../../containers/Template';
import Box from '../../components/styled/Box';
import Input from '../../components/Input';
import Title from '../../components/Title';
import Cards from '../../components/Cards';
import * as actions from '../../modules/library/actions';
import { AppState } from '../../redux/store';
import { LibraryState } from '../../modules/library/types';

const { Card } = Cards;

interface Props {
  theme: string;
  library: LibraryState;
  changeTheme: Function;
  getLibrary: Function;
}

class Library extends Component<Props> {
  state = {
    search: '',
  };

  componentDidMount() {
    const { getLibrary } = this.props;
    getLibrary();
  }

  searchHandle = (event: any) => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const {
      theme,
      // @ts-ignore
      library: { collection = [] },
      changeTheme,
    } = this.props;
    const { search } = this.state;

    return (
      <Template changeTheme={changeTheme}>
        <Title description="Perhaps this will interest you">Library</Title>
        <Box p="14px 0 21px">
          <Input
            type="text"
            onChange={this.searchHandle}
            placeholder="Search by collections name or artist"
          />
        </Box>
        <Cards>
          {collection.length
            ? collection
                .filter((item: any) => item.title.toLowerCase().includes(search))
                .map((item: any) => (
                  <Link key={item.id} to={`/playlist/${item.slug}`} className="inherit-link">
                    <Card
                      imageSrc={item.imageSrc}
                      title={item.title}
                      status="available"
                      progress={item.progress}
                      theme={theme}
                      fromLang={item.fromLang}
                      toLang={item.toLang}
                    />
                  </Link>
                ))
            : 'Empty Library'}
        </Cards>
      </Template>
    );
  }
}

const connector = connect(
  ({ app: { theme }, library }: AppState) => ({ theme, library }),
  // @ts-ignore
  (dispatch) => bindActionCreators(actions, dispatch)
);

export default connector(Library);
