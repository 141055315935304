import styled from 'styled-components';

const Input = styled.input<{ icon?: string }>`
  font-family: ${(props) => props.theme.fontFamily};
  background-color: ${(props) => props.theme.inputBackground};
  color: ${(props) => props.theme.textColor};
  border: none;
  font-size: 14px;
  padding: ${(props) => (props.icon ? '12px 20px 12px 40px' : '12px 20px')};
  width: 100%;
  border-radius: 5px;
  outline: none;

  ::placeholder {
    color: ${(props) => props.theme.placeholderColor};
    opacity: 1;
  }
`;

export default Input;
