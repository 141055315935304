import React from 'react';
import { Icon, IconSvg } from './index';

const Icons: any = {
  '-1': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 357 357">
        <path fill="#566fff" d="M38.25 0v357l280.5-178.5z" />
      </IconSvg>
    </Icon>
  ),
  '0': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 408 408">
        <path
          d="M346.8 61.2C311.1 22.95 260.1 0 204 0 91.8 0 0 91.8 0 204s91.8 204 204 204c94.35 0 173.4-66.3 196.35-153H346.8c-20.4 58.65-76.5 102-142.8 102-84.15 0-153-68.85-153-153S119.85 51 204 51c43.35 0 79.05 17.85 107.1 45.9l-81.6 81.6H408V0l-61.2 61.2z"
          fill="#566fff"
        />
      </IconSvg>
    </Icon>
  ),
  '1': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 357 357">
        <path d="M25.5 357h102V0h-102v357zm204-357v357h102V0h-102z" fill="#566fff" />
      </IconSvg>
    </Icon>
  ),
  '2': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 357 357">
        <path fill="#566fff" d="M38.25 0v357l280.5-178.5z" />
      </IconSvg>
    </Icon>
  ),
  '3': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 357 357">
        <path fill="#566fff" d="M38.25 0v357l280.5-178.5z" />
      </IconSvg>
    </Icon>
  ),
  '5': () => (
    <Icon>
      <IconSvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 357 357">
        <path fill="#566fff" d="M38.25 0v357l280.5-178.5z" />
      </IconSvg>
    </Icon>
  ),
};

const ActionIcons = ({
  videoState,
  onClick,
}: {
  videoState?: string | null;
  onClick: Function;
}) => {
  const VideoStateIcon = videoState && videoState !== null ? Icons[videoState] : Icons[-1];
  return (
    <div onClick={() => onClick(videoState)}>
      <VideoStateIcon />
    </div>
  );
};

export default ActionIcons;
