import { AuthActionTypes, UserSettingsType } from './types';

export const getUser = (id: string) => ({
  type: AuthActionTypes.GET_USER_REQUEST,
  payload: id,
});

export const setUserParams = ({ song, token }: { song: string; token: string }) => ({
  type: AuthActionTypes.SET_USER_PARAMS,
  payload: { song, token },
});

export const logout = () => ({
  type: AuthActionTypes.LOGOUT,
});

export const updateUserSettings = (payload: { id: string; settings: UserSettingsType }) => ({
  type: AuthActionTypes.UPDATE_USER_SETTINGS_REQUEST,
  payload,
});
