import React, { useState } from 'react';
import Tours from 'reactour';
import steps from './config';

const Tour = ({ onClose }: { onClose?: Function }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Tours
      accentColor="#566fff"
      steps={steps}
      isOpen={isOpen}
      rounded={5}
      showCloseButton={false}
      closeWithMask={false}
      onRequestClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      showNavigation={false}
      showButtons={false}
    />
  );
};

export default Tour;
