import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from '../../containers/Template';
import Title from '../../components/Title';
import Text from '../../components/Text';
import PointsBadge from '../../components/PointsBadge';
import Box from '../../components/styled/Box';
import { AppState } from '../../redux/store';
import { AuthState } from '../../modules/auth/types';

interface Props {
  changeTheme: Function;
  auth: AuthState;
}

class Loyalty extends Component<Props> {
  render() {
    const {
      changeTheme,
      auth: { user },
    } = this.props;

    return (
      <Template changeTheme={changeTheme}>
        <Title description="Your virtual money">Loyalty</Title>
        <Box m="10px 0 20px">
          <PointsBadge points={user && user.score} />
        </Box>
        <Text>
          The points system will allow you to buy additional content and more. In order to get them
          now, simply share songs with friends. In the near future, we will expand our functionality
          and add the ability to both receive them and the ability to monitor them.
        </Text>
      </Template>
    );
  }
}

const connector = connect(({ auth }: AppState) => ({ auth }));

export default connector(Loyalty);
