import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { connect } from 'react-redux';

const themesCommon = {
  // Spacing
  pagePadding: '25px 30px',
  breakpointsMixin: {
    tablet: '768px',
    desktop: '1180px',
  },
};

export const themes: any = {
  default: {
    fontFamily: 'Noto Sans, sans-serif',
    colorPrimary: '#566fff',
    bodyBackground: '#ffffff',
    borderColor: '#eeeeee',
    textColor: '#434343',
    textLineInfo: '#fffbe6',
    // Player
    progress: '#eeeeee',
    progressFill: '#566fff',
    // Forms
    inputBackground: '#f6f6f6',
    placeholderColor: '#cccccc',
    ...themesCommon,
  },
  dark: {
    colorPrimary: '#566fff',
    bodyBackground: '#34425D',
    borderColor: '#2d3a52',
    textColor: '#ACBAE5',
    textLineInfo: '#566fff',
    // Player
    progress: '#ACBAE5',
    progressFill: '#566fff',
    // Forms
    inputBackground: '#4b5a77',
    placeholderColor: '#ACBAE5',
    ...themesCommon,
  },
};

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    color: ${(props: any) => props.theme.textColor};
    background-color: ${(props) => props.theme.bodyBackground};
    font-size: 13px;
    font-family: Noto Sans, sans-serif;
    line-height: 1.6em;
  }

  a {
    color: #566fff;
  }

  a.inherit-link {
    color: inherit;
    text-decoration: inherit;
  }

  .reactour__helper {
    outline: none;
  }

  .link {
    color: #566fff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface Props {
  children: Object;
  app: {
    theme: string;
  };
}

class ThemeWrapper extends React.Component<Props> {
  render() {
    const {
      children,
      app: { theme },
    } = this.props;

    return (
      // @ts-ignore
      <ThemeProvider theme={themes[theme]}>
        <>
          <GlobalStyle />
          {children}
        </>
      </ThemeProvider>
    );
  }
}

// @ts-ignore
const connector = connect(({ app }) => ({ app }));

export default connector(ThemeWrapper);
