import React, { FC } from 'react';
import useClipboard from 'react-use-clipboard';
import { Flex, Box } from '../styled';
import Title from '../Title';
import Input from '../Input';
import styled from 'styled-components';
import Button from '../Button';
import Text from '../Text';

const ButtonCopy = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 80px;
  height: 50px;
  cursor: pointer;
`;

const Information = styled(Box)`
  background: #ffe58f;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
`;

type Props = {
  songId: string;
  playlistId: string;
  onBack?: Function;
};

const SuccessSong: FC<Props> = ({ songId, playlistId, onBack }) => {
  const linkToCopy = `${window.location.origin}?song=${songId}&playlist=${playlistId}&source=share`;
  const [isCopied, setCopied] = useClipboard(linkToCopy, {
    successDuration: 5000,
  });

  return (
    <Flex alignItems="center" flexDirection="column" mt="15%">
      <Box mb="20px">
        <span role="img" aria-label="" style={{ fontSize: '110px', lineHeight: '1em' }}>
          🎉
        </span>
      </Box>
      <Box p="0 25px" textAlign="center">
        <Title>Так держать!</Title>
        <Box mt="25px" mb="55px" style={{ fontSize: 16, lineHeight: 1.6 }}>
          <p>Продолжайте практиковаться, и вы обязательно добьетесь поставленной цели.</p>

          <p>
            Завтра мы пришлем вам новую песню, но если не терпится продолжить сегодня, то вы можете
            использовать Очки лояльности которые позволят незамедлительно запросить новую песню.
          </p>
          <Information marginTop="30px">
            <Box mb="15px">
              Если вам понравилась эта песня и вы хотите поделиться ею со своим другом, просто
              используйте ссылку ниже:
            </Box>
            <Flex>
              <Input value={linkToCopy} readOnly style={{ marginRight: 5 }} />
              <ButtonCopy onClick={setCopied} theme={isCopied && 'success'}>
                {isCopied ? (
                  <span style={{ fontSize: 26 }} role="img" aria-label="">
                    👌
                  </span>
                ) : (
                  <svg
                    viewBox="0 0 14 16"
                    version="1.1"
                    width="14"
                    height="16"
                    aria-hidden="true"
                    style={{ width: 20, height: 20, fill: '#fff' }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 13h4v1H2v-1zm5-6H2v1h5V7zm2 3V8l-3 3 3 3v-2h5v-2H9zM4.5 9H2v1h2.5V9zM2 12h2.5v-1H2v1zm9 1h1v2c-.02.28-.11.52-.3.7-.19.18-.42.28-.7.3H1c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h3c0-1.11.89-2 2-2 1.11 0 2 .89 2 2h3c.55 0 1 .45 1 1v5h-1V6H1v9h10v-2zM2 5h8c0-.55-.45-1-1-1H8c-.55 0-1-.45-1-1s-.45-1-1-1-1 .45-1 1-.45 1-1 1H3c-.55 0-1 .45-1 1z"
                    ></path>
                  </svg>
                )}
              </ButtonCopy>
            </Flex>
          </Information>
          {/* Create portal for button @back into header component */}
          <Text textAlign="left" className="link" onClick={() => onBack && onBack()}>
            Вернуться к песне
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default SuccessSong;
