import React, { FC } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { RouterState } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { AuthState } from '../../modules/auth/types';
import { AppState, history } from '../../redux/store';
import { Link } from 'react-router-dom';

const TemplateStyled = styled.div<{ contentPadding?: string }>`
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    line-height: 0;
    height: 60px;
  }
  .header__burger {
    cursor: pointer;
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.textColor};
    margin-left: auto;
    padding: 0 30px;
    height: 100%;
    box-sizing: content-box;
  }
  .main {
    padding: ${({ theme, contentPadding }) => contentPadding || theme.pagePadding};
  }
  .header__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0 30px;
    height: 100%;
  }
  .header__back-icon {
    width: 20px;
    padding-right: 8px;
    box-sizing: content-box;
    fill: ${({ theme }) => theme.textColor};
  }
  .header__burger-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 100%;
  }
`;

interface Props {
  router: RouterState;
  app: {
    route: string;
    query: {
      song: string;
    };
  };
  logout: Function;
  auth: AuthState;
  children: Object;
  contentPadding?: string;
  changeTheme: Function;
}

const Template: FC<Props> = ({ contentPadding, children }) => {
  const router = useSelector((state: AppState) => state.router);

  return (
    <TemplateStyled contentPadding={contentPadding}>
      <header className="header">
        {/* not main page */}
        {router.location.pathname !== '/' && (
          <div className="header__back" onClick={() => history.goBack()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 129 129"
              className="header__back-icon"
            >
              <path d="M88.6 121.3c.8.8 1.8 1.2 2.9 1.2s2.1-.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0l-54 53.9c-1.6 1.6-1.6 4.2 0 5.8l54 53.9z" />
            </svg>
            Back
          </div>
        )}

        <Link to="/settings" className="header__burger-link">
          <svg xmlns="http://www.w3.org/2000/svg" className="header__burger" viewBox="0 0 512 512">
            <path d="M491.318 235.318H20.682C9.26 235.318 0 244.577 0 256s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 78.439H20.682C9.26 78.439 0 87.699 0 99.121c0 11.422 9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.26 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 392.197H20.682C9.26 392.197 0 401.456 0 412.879s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682s-9.259-20.682-20.682-20.682z" />
          </svg>
        </Link>
      </header>
      <main className="main">{children}</main>
    </TemplateStyled>
  );
};

// @ts-ignore
export default withRouter(Template);
