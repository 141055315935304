import { AppActionTypes, ChangeThemeAction } from './types';

export const changeTheme = (): ChangeThemeAction => ({
  type: AppActionTypes.APP_CHANGE_THEME,
});

export const setAppVersion = (payload: string) => ({
  type: AppActionTypes.SET_APP_VERSION,
  payload,
});

export const changeTabView = (payload: 'parallel' | 'alternation' | 'change') => ({
  type: AppActionTypes.CHANGE_TAB_VIEW,
  payload,
});
