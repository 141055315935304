import queryString from 'query-string';
import { LOCATION_CHANGE } from 'connected-react-router';
import { AppActionTypes } from './types';

const INITIAL_STATE: any = {
  theme: 'default',
  tabView: 'parallel',
  version: '',
  query: null,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case AppActionTypes.APP_CHANGE_THEME:
      const theme = state.theme === 'default' ? 'dark' : 'default';
      return { ...state, theme: action.payload || theme };

    case AppActionTypes.SET_APP_VERSION:
      return {
        ...state,
        version: action.payload,
      };

    case AppActionTypes.CHANGE_TAB_VIEW:
      return {
        ...state,
        tabView: action.payload,
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        query: queryString.parse(action.payload.location.search),
      };

    default:
      return state;
  }
}
