import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { takeEvery, call, select } from 'redux-saga/effects';
import { pageview, event } from '../../lib/gtag';
import { AppActionTypes } from '../app/types';
import { AppState } from '../../redux/store';

function* locationChangeSaga(action: LocationChangeAction) {
  // Хак для того чтобы Helmet успел сделать все свои дела до отправки цели;
  yield call(setTimeout, () => pageview(action.payload.location.pathname), 0);
}

function* appChangeThemeSaga() {
  const theme = yield select((state: AppState) => state.app.theme);
  yield call(event, {
    category: 'Theme',
    action: 'Changed',
    label: theme === 'default' ? 'dark' : 'default',
  });
}

export function* RequestsSagaWatcher() {
  yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(AppActionTypes.APP_CHANGE_THEME, appChangeThemeSaga);
}

export default RequestsSagaWatcher;
