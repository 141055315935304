import React from 'react';
import styled from 'styled-components';

const PointsBadgeStyled = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  padding: 3px 15px;
  background: #fff;
  color: #ffa92b;
  border-radius: 5px;
  box-shadow: 0px 2px 7px 0px rgba(107, 107, 107, 0.3);

  .points-badge__icon {
    width: 15px;
    height: 15px;
    fill: #ffa92b;
    margin-right: 8px;
  }
`;

const PointsBadge = ({ points }: { points: any }) => (
  <PointsBadgeStyled className="points-badge">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="512"
      viewBox="0 0 512.001 512"
      width="512"
      className="points-badge__icon"
    >
      <path d="M497 36.953h-65.703c.238-7.277.371-14.598.371-21.953 0-8.285-6.719-15-15-15H95.332c-8.281 0-15 6.715-15 15 0 7.355.133 14.676.371 21.953H15c-8.285 0-15 6.715-15 15 0 67.211 17.566 130.621 49.46 178.555 31.528 47.387 73.524 74.566 118.892 77.21 10.285 11.192 21.156 20.317 32.48 27.278v66.668h-25.164c-30.422 0-55.168 24.75-55.168 55.168v25.164h-1.066c-8.286 0-15 6.719-15 15 0 8.285 6.714 15 15 15h273.132c8.286 0 15-6.715 15-15 0-8.281-6.714-15-15-15H391.5v-25.164c0-30.418-24.746-55.168-55.168-55.168h-25.164v-66.668c11.324-6.957 22.2-16.086 32.484-27.277 45.364-2.645 87.36-29.824 118.891-77.211C494.437 182.574 512 119.164 512 51.953c0-8.285-6.715-15-15-15zM74.437 213.891c-26.308-39.536-41.765-91.246-44.12-146.938h52.062c5.41 68.461 21.48 131.742 46.598 181.973 4 8 8.183 15.558 12.523 22.676-24.95-9.422-48.04-29.118-67.063-57.711zM361.5 456.832V482h-211v-25.168c0-13.875 11.29-25.168 25.168-25.168h160.664c13.879 0 25.168 11.293 25.168 25.168zm-80.332-55.168h-50.336v-53.633c8.246 2.172 16.648 3.301 25.168 3.301s16.922-1.129 25.168-3.3zm9.289-89.32c-.676.277-1.324.613-1.945.984-10.602 5.274-21.496 8.004-32.512 8.004-11.012 0-21.902-2.73-32.5-7.996a14.973 14.973 0 0 0-1.965-1c-11.762-6.219-23.14-15.61-33.902-27.95a15.144 15.144 0 0 0-1.91-2.234c-10.684-12.683-20.735-28.285-29.914-46.644C128.242 180.378 112.32 107.89 110.508 30h290.984c-1.816 77.89-17.738 150.383-45.3 205.508-9.18 18.36-19.231 33.96-29.91 46.644-.712.68-1.356 1.43-1.919 2.239-10.761 12.343-22.144 21.73-33.906 27.953zm147.106-98.453c-19.024 28.593-42.114 48.289-67.063 57.71a308.862 308.862 0 0 0 12.523-22.675c25.118-50.23 41.184-113.512 46.598-181.973h52.063c-2.356 55.692-17.813 107.402-44.122 146.938zm0 0" />
    </svg>
    {points}
  </PointsBadgeStyled>
);

export default PointsBadge;
