import { put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { PlaylistActionTypes } from './types';
import { enhancePlaylist } from './normalize';

function* getPlaylistSaga(action: any) {
  try {
    const data = yield api.getPlaylist(action.payload.slug);
    yield put({
      type: PlaylistActionTypes.GET_PLAYLIST_SUCCESS,
      payload: enhancePlaylist(data),
    });
  } catch (error) {
    yield put({ type: PlaylistActionTypes.GET_PLAYLIST_FAILURE });
  }
}

function* subscribeToPlaylistSaga(action: any) {
  try {
    const data = yield api.subscribeToPlaylist(action.payload.userId, action.payload.slug);
    yield put({
      type: PlaylistActionTypes.SUBSCRIBE_TO_PLAYLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: PlaylistActionTypes.SUBSCRIBE_TO_PLAYLIST_FAILURE });
  }
}

export function* PlaylistSagaWatcher() {
  yield;
  yield takeLatest(PlaylistActionTypes.GET_PLAYLIST_REQUEST, getPlaylistSaga);
  yield takeLatest(PlaylistActionTypes.SUBSCRIBE_TO_PLAYLIST_REQUEST, subscribeToPlaylistSaga);
}

export default PlaylistSagaWatcher;
