import { select, take } from 'redux-saga/effects';
import sentry from './';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { SENTRY_DSN, APP_ENV } from '../../config/env';

export default function* sentrySaga() {
  const { user } = yield select((state) => state.auth);

  if (SENTRY_DSN) sentry.init({ SENTRY_DSN, environment: APP_ENV });

  if (user && user.id) {
    sentry.setUser({ id: user.id });
  }

  if (user) {
    sentry.setExtraContext({
      user,
    });
  }

  while (true) {
    const action = yield take('*');
    addBreadcrumb({
      message: action.type,
      category: 'Action',
      data: action,
      level: !action.error ? Severity.Info : Severity.Error,
    });
  }
}
