import React, { FC } from 'react';
import { Flex, Box } from '../styled';
import Title from '../Title';
import Input from '../Input';
import styled from 'styled-components';
import Button from '../Button';
import Text from '../Text';
import { event } from '../../lib/gtag';
import api from '../../api';
import { media } from '../../utils/mixins';

const ButtonCopy = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  padding: 0 20px;
  flex: 1 0 auto;
  margin-top: 5px;
  ${media.tablet`
    margin-top: 0;
  `}
`;

const Information = styled(Box)`
  background: #ffe58f;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
  `}
`;

type Props = {
  songId: string;
  playlistId: string;
  onBack?: Function;
};

const SubscribePromo: FC<Props> = ({ playlistId, onBack }) => {
  const [email, setEmail] = React.useState('');
  const [sended, setSended] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const subscribeByEmail = async (email: string) => {
    setLoading(true);
    try {
      await api.subscribeByEmail(email);
      setSended(true);
      // Logger
      event({
        category: 'subscribe',
        action: 'email',
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    subscribeByEmail(email);
  };

  return (
    <Flex alignItems="center" flexDirection="column" mt="15%">
      <Box mb="20px">
        <span role="img" aria-label="" style={{ fontSize: '110px', lineHeight: '1em' }}>
          🎉
        </span>
      </Box>
      <Box p="0 25px" textAlign="center">
        <Title>Получайте песни каждый день!</Title>
        <Box mt="25px" mb="55px" style={{ fontSize: 16, lineHeight: 1.6 }}>
          <p>
            Для того чтобы практиковаться и получать песни каждый день, вам достаточно просто
            подписаться на нашего{' '}
            <a
              href={`tg://resolve?domain=daySongBot&start=p-${playlistId}`}
              onClick={() => {
                event({
                  category: 'bot',
                  action: 'open',
                });
              }}
            >
              Telegram бота
            </a>
            .
          </p>

          <Information marginTop="30px">
            <Box mb="15px">
              Если у вас нет Telegram, не беда, в скором времени подписка будет доступна и по Email.
              Уже сейчас вы можете оставить свой Email и, когда мы будем готовы, вы начнете получать
              песни каждый день. Обещаем не слать ничего лишнего, и у вас будет возможность
              отписаться в любой момент.
            </Box>
            <Form onSubmit={handleSubmit}>
              <Input
                placeholder="Введите ваш Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                style={{ marginRight: 5 }}
                disabled={sended}
              />
              <ButtonCopy type="submit" theme={sended && 'success'} disabled={sended || loading}>
                {sended ? 'Будем на связи 👌' : 'Подписаться'}
              </ButtonCopy>
            </Form>
          </Information>
          {/* Create portal for button @back into header component */}
          <Text textAlign="left" className="link" onClick={() => onBack && onBack()}>
            Вернуться к песне
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default SubscribePromo;
