import React from 'react';
import Aside from '../../components/Aside';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, history } from '../../redux/store';
import { logout } from '../../modules/auth/actions';
import { changeTheme } from '../../modules/app/actions';

const Settings = () => {
  const { auth, song } = useSelector((state: AppState) => ({
    auth: state.auth,
    song: state.song,
  }));

  const dispatch = useDispatch();

  let songLabel = '';

  if (auth.user && song.selected) {
    const { performer, title } = song.selected;
    songLabel = `${performer} - ${title}`;
  }

  return (
    <Aside
      auth={auth}
      changeTheme={() => dispatch(changeTheme())}
      onClose={() => history.goBack()}
      onLogout={() => dispatch(logout())}
      songLabel={songLabel}
      opened
    />
  );
};

export default Settings;
