export interface SongState {
  loading: boolean;
  selected: SongType | null;
}

export interface SongType {
  title: string;
  note: string;
  performer: string;
  fileID: string;
  url: string;
  hash: string;
  playList: string;
  lyrics: any;
  payed: boolean;
}

export enum SongActionTypes {
  GET_SONG_REQUEST = '@@song/GET_SONG_REQUEST',
  GET_SONG_SUCCESS = '@@song/GET_SONG_SUCCESS',
  GET_SONG_FAILURE = '@@song/GET_SONG_FAILURE',
}
