import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Promo from '../../components/Promo';
import Playlist from '../../components/Playlist';
import Template from '../../containers/Template';
import * as appActions from '../../modules/app/actions';
import * as actions from '../../modules/auth/actions';
import { AppState } from '../../redux/store';
import { AuthState } from '../../modules/auth/types';
import { SongState } from '../../modules/song/types';

interface Props {
  app: any;
  theme: string;
  song: SongState;
  changeTheme: Function;
  auth: AuthState;
  changeTabView: typeof appActions.changeTabView;
  updateUserSettings: typeof actions.updateUserSettings;
  onCloseIntroVisitedHandler: Function;
}

class Home extends Component<Props> {
  onCloseIntroHandler = () => {
    const { auth, updateUserSettings } = this.props;
    if (auth.user) {
      updateUserSettings({
        id: auth.user.id,
        settings: { introVisited: true },
      });
    }
  };
  render() {
    const { app, auth, song, theme, changeTheme, changeTabView } = this.props;

    return (
      <div>
        <Helmet>
          <title>Daily Song Bot</title>
          <meta
            name="Description"
            content="Every morning Ding-Dong will send you a song that you will have to master."
          />
        </Helmet>
        <Template contentPadding="0">
          {song.selected ? (
            <Playlist
              song={song.selected}
              theme={theme}
              changeTheme={changeTheme}
              tabView={app.tabView}
              changeTabView={changeTabView}
              introVisited={auth.user?.settings?.introVisited ?? true}
              onCloseIntro={this.onCloseIntroHandler}
              guest={!Boolean(auth.user)}
              //@ts-ignore
              price={auth.user ? auth.user.library[song.selected.playList].price : ''}
            />
          ) : (
            <Promo />
          )}
        </Template>
      </div>
    );
  }
}

const connector = connect(
  ({ auth, app, song }: AppState) => ({ auth, app, song }),
  // @ts-ignore
  (dispatch) => bindActionCreators({ ...actions, ...appActions }, dispatch)
);

// @ts-ignore
export default connector(Home);
