import { SongState, SongActionTypes } from './types';

const INITIAL_STATE: SongState = {
  selected: null,
  loading: false,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SongActionTypes.GET_SONG_REQUEST:
      return { ...state, loading: true };
    case SongActionTypes.GET_SONG_SUCCESS:
      return { ...state, selected: action.payload, loading: false };
    case SongActionTypes.GET_SONG_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
