import { PaymentState, PaymentActionTypes } from './types';

const INITIAL_STATE: PaymentState = {
  loading: false,
  error: null,
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case PaymentActionTypes.START:
      return { ...state, loading: true };
    case PaymentActionTypes.COMPLETE:
      return { ...state, loading: false };
    case PaymentActionTypes.ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
