import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { Box, Flex } from '../styled';
import { ReactourStepContentArgs } from 'reactour';
import Text from '../Text';
import { themes } from '../../containers/ThemeWrapper';

const Title = styled.h2`
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
`;

const steps = [
  {
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Привет{' '}
            <span role="img" aria-label="Waving Hand">
              👋
            </span>
          </Title>
          Рад тебя видеть, давай я покажу как тут все устроено.
        </Box>
        <Button onClick={() => goTo(step)}>Давай!</Button>
      </Box>
    ),
  },
  {
    selector: '.player',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Плеер{' '}
            <span role="img" aria-label="Musical Score">
              🎼
            </span>
          </Title>
          <Text>
            С помощью этой панели ты можешь управлять плеером, запускать, ставить на паузу и
            перематывать.
          </Text>
        </Box>
        <Button onClick={() => goTo(step)}>Плеер — это хорошо!</Button>
      </Box>
    ),
  },
  {
    selector: '.song-item-container',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Текст{' '}
            <span role="img" aria-label="Memo">
              📝
            </span>
          </Title>
          <Text>Можно следить за оригиналом и переводом текста.</Text>
        </Box>
        <Button onClick={() => goTo(step)}>Окей, я понял!</Button>
      </Box>
    ),
  },
  {
    selector: '.song-item-container',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Title>
          Комментарии преподователя{' '}
          <span role="img" aria-label="Woman Teacher">
            👩‍🏫
          </span>
        </Title>
        <Box mb="20px">
          Также некоторые строчки песен могут быть выделены{' '}
          <span
            style={{
              backgroundColor: themes.default.textLineInfo,
              padding: '1px 5px',
              borderRadius: '5px',
              border: '1px solid rgb(247, 237, 185)',
            }}
          >
            желтым цветом
          </span>
          , это означает, что преподаватель оставил для тебя специальный комментарий.{' '}
          <span role="img" aria-label="Writing Hand">
            ✍️
          </span>
        </Box>
        <Button onClick={() => goTo(step)}>Преподаватель? Супер!</Button>
      </Box>
    ),
  },
  {
    selector: '.tabs',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Перевод{' '}
            <span role="img" aria-label="Globe With Meridians">
              🌐
            </span>
          </Title>
          <Text>
            У нас есть три вида отображения текста - выбери свой вариант.{' '}
            <span role="img" aria-label="Flexed Biceps">
              💪
            </span>
          </Text>
        </Box>
        <Button onClick={() => goTo(step)}>Окей, попробую!</Button>
      </Box>
    ),
  },
  {
    selector: '.header-song__theme',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Ночной режим{' '}
            <span role="img" aria-label="Crescent Moon">
              🌙
            </span>
          </Title>
          <Text>Также ты можешь включить ночной режим, если белый тебе не по душе.</Text>
        </Box>
        <Button onClick={() => goTo(step)}>Ага!</Button>
      </Box>
    ),
  },
  {
    selector: '.header__burger-link',
    content: ({ goTo, step }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>Меню ⚙</Title>
          <Text>Меню - в нем ты можешь найти библиотеку плейлистов и прочее.</Text>
        </Box>
        <Button onClick={() => goTo(step)}>Посмотрю!</Button>
      </Box>
    ),
  },
  {
    content: ({ goTo, close }: ReactourStepContentArgs) => (
      <Box>
        <Box mb="20px">
          <Title>
            Поехали{' '}
            <span role="img" aria-label="Party Popper">
              🎉
            </span>
          </Title>
          <Text>
            Вот и все! Я также буду очень рад, если ты поделишься своим позитивным или негативным
            опытом с моими разработчиками, поэтому если есть что сказать, смело пиши @nemod503 или
            @forwardomg, они выслушают и помогут.
          </Text>
        </Box>
        <Flex alignItems="center">
          <Button onClick={() => close()}>Давай начнем!</Button>
          <Box ml="20px">
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                goTo(0);
              }}
            >
              Повторить
            </a>
          </Box>
        </Flex>
      </Box>
    ),
  },
];

export default steps;
