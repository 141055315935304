import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './redux/store';
import ThemeWrapper from './containers/ThemeWrapper';
import Home from './pages/Home';
import Library from './pages/Library';
import Terms from './pages/Terms';
import Loyalty from './pages/Loyalty';
import Playlist from './pages/Playlist';
import Settings from './pages/Settings';
import './App.css';
import Partnership from './pages/Partnership';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeWrapper>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/partnership" exact component={Partnership} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/library" exact component={Library} />
            <Route path="/loyalty" exact component={Loyalty} />
            <Route path="/playlist/:id" exact component={Playlist} />
            {/* Docs */}
            <Route path="/terms" exact component={Terms} />
          </Switch>
        </ConnectedRouter>
      </ThemeWrapper>
    </Provider>
  );
};

export default App;
