export type AuthState = {
  user: UserType | null;
  token: string;
  song: string;
  loading: boolean;
};

export type UserType = {
  avatar: string;
  firstName: string;
  id: string;
  languageCode: string;
  lastName: string;
  userName: string;
  score: string;
  settings: UserSettingsType;
};

export type UserSettingsType = {
  introVisited: boolean;
};

export enum AuthActionTypes {
  GET_USER_REQUEST = '@@auth/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@@auth/GET_USER_SUCCESS',
  GET_USER_FAILURE = '@@auth/GET_USER_FAILURE',

  SET_USER_PARAMS = '@@auth/SET_USER_PARAMS',
  LOGOUT = '@@auth/LOGOUT',

  UPDATE_USER_SETTINGS_REQUEST = '@@auth/UPDATE_USER_SETTINGS_REQUEST',
  UPDATE_USER_SETTINGS_SUCCESS = '@@auth/UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_FAILURE = '@@auth/UPDATE_USER_SETTINGS_FAILURE',
}
