import React, { ReactNode } from 'react';
import styled from 'styled-components';

const MenuStyled = styled.div`
  font-size: 14px;
  // margin: 20px 0;
`;

const MenuItem = styled.div`
  .menu__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
  .menu__icon {
    line-height: 0;
    margin-right: 13px;
    fill: ${({ theme }) => theme.textColor};
  }
  .menu__icon svg {
    width: 24px;
    height: 24px;
    line-height: 0;
  }
`;

interface MenuProps {
  children: ReactNode;
}

const Menu = ({ children }: MenuProps) => {
  return (
    <MenuStyled>
      <MenuItem>{children}</MenuItem>
    </MenuStyled>
  );
};

interface MenuItemProps {
  children: ReactNode;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<JSX.Element | HTMLElement>;
}

Menu.Item = ({ children, icon, onClick }: MenuItemProps) => {
  return (
    <div onClick={onClick} className="menu__item">
      {icon && <div className="menu__icon">{icon}</div>}
      <span>{children}</span>
    </div>
  );
};

export default Menu;
