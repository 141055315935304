import { css } from 'styled-components';
import { themes } from '../containers/ThemeWrapper';

// ${media.tablet`
//   background-color: aqua;
// `}
export const media = Object.keys(themes.default.breakpointsMixin).reduce(
  (acc: { [key: string]: Function }, key) => {
    acc[key] = (...rest: any) => css`
      @media (min-width: ${themes.default.breakpointsMixin[key]}) {
        // @ts-ignore
        ${css(...rest)};
      }
    `;
    return acc;
  },
  {}
);
