import React from 'react';
import styled from 'styled-components';

const AvatarStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  .avatar__name {
  }
  .avatar__description {
    font-size: 12px;
    color: #cccccc;
  }
`;

const ImgStyled = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
`;

interface Props {
  src: string;
  name: string;
  description?: string;
}

const Avatar = ({ src, name, description }: Props) => (
  <AvatarStyled>
    <ImgStyled src={src || '/static/placeholder.jpg'} alt={name} />
    <div>
      <div className="avatar__name">{name}</div>
      <div className="avatar__description">{description}</div>
    </div>
  </AvatarStyled>
);

export default Avatar;
