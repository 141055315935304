import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from './reducers';
import rootSaga from './sagas';

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error) => {
    // Errors does not bubble errors up to the browsers uncaught exception handler.
    // That's becouse we use onError callback for sending uncaught exceptions to error tracking service.
    // Display error
    console.error('Error in Saga', error);
  },
});

// Middlewares for all env
const middlewares = [sagaMiddleware, routerMiddleware(history)];

export const rootReducer = createRootReducer(history);

const store = createStore(
  rootReducer,
  // @ts-ignore
  composeWithDevTools(
    applyMiddleware(...middlewares),
    // @ts-ignore
    persistState(['app', 'auth'])
  )
);

sagaMiddleware.run(rootSaga);

// Types
export type AppState = ReturnType<typeof rootReducer>;

export default store;
