import React, { FC } from 'react';
import styled from 'styled-components';
import Template from '../../containers/Template';
import { Box } from '../../components/styled';
import { Helmet } from 'react-helmet';

const TermsComponent: FC<{ className: string }> = ({ className }) => {
  return (
    <div className={className}>
      <Helmet>
        <title>Daily Song Bot | Пользовательское соглашение</title>
        <meta name="Description" content="Знаешь английский и любишь музыку? - приходи к нам!" />
      </Helmet>
      <Template>
        <Box>
          <h1>Пользовательское соглашение сервиса DailySong.</h1>
          <ol>
            <li>
              <h2>Общие положения</h2>
              <ol>
                <li>
                  <p>
                    Администрация сервиса DailySong (далее &mdash; &laquo;Администрация&raquo;)
                    предлагает пользователю сети Интернет (далее &ndash; Пользователь) -
                    использовать свои сервисы на условиях, изложенных в настоящем Пользовательском
                    соглашении (далее &mdash; &laquo;Соглашение&raquo;, &laquo;ПС&raquo;).
                    Соглашение вступает в силу с момента выражения Пользователем согласия с его
                    условиями в порядке, предусмотренном п. 1.4 Соглашения.
                  </p>
                </li>
                <li>
                  <p>Термины и определения:</p>
                  <ol>
                    <li>
                      <p>Бот - Бот в мессенджере telegram под ником @daySongBot.</p>
                    </li>
                    <li>
                      <p>
                        Сервис - Бот, Сайт сервиса, каналы взаимодействия с Администрацией. В
                        совокупности направленные на предоставление пользователю доступа к песням.
                      </p>
                    </li>
                    <li>
                      <p>
                        Сайт, Сайт сервиса - Сайт в сети интернет{' '}
                        <a href="https://dailysong.ru/">
                          <u>https://dailysong.ru/</u>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        Песня - Текст песни и ее перевод на другой язык, который может быть доступен
                        по определенной ссылке на сайте сервиса.
                      </p>
                    </li>
                    <li>
                      <p>
                        Комментарии к песне - у каждой строки текста песни может быть комментарий,
                        который проясняет понимание текста песни.
                      </p>
                    </li>
                    <li>
                      <p>
                        Плейлист, playlist, коллекция песен, набор песен - несколько песен, которые
                        объединены в коллекцию.
                      </p>
                    </li>
                    <li>
                      <p>
                        Подписка на плейлист - Желание пользователя узнавать о песнях в конкретном
                        плейлисте.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Соглашение может быть изменено Администрацией без какого-либо специального
                    уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в
                    сети Интернет по указанному в настоящем абзаце адресу, если иное не
                    предусмотрено новой редакцией Соглашения. Действующая редакция ПС всегда
                    находится на странице по адресу{' '}
                    <a href="https://dailysong.ru/terms">
                      <u>https://dailysong.ru/terms</u>
                    </a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Начиная использовать сервис/его отдельные функции, посещая сайт, либо нажимая
                    start в диалоговом окне с ботом в мессенджере telegram, Пользователь считается
                    принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений.
                    В случае несогласия Пользователя с какими-либо из положений Соглашения,
                    Пользователь не вправе использовать сервис. В случае если Администрацией были
                    внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3
                    Соглашения, с которыми Пользователь не согласен, он обязан прекратить
                    использование сервиса.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Регистрация Пользователя.</h2>
              <ol>
                <li>
                  <p>
                    Для того чтобы воспользоваться некоторыми сервисом или некоторыми отдельными
                    функциями сервиса, Пользователю необходимо пройти процедуру регистрации, в
                    результате которой для Пользователя будет создана уникальная учетная запись.
                  </p>
                </li>
                <li>
                  <p>
                    Регистрация в сервисе происходит путем начала диалога с ботом. Сервис не
                    собирает дополнительную информацию о пользователе, кроме той, что была
                    предоставлена пользователем мессенджеру telegram и доступна через telegram bot
                    api.
                  </p>
                </li>
                <li>
                  <p>
                    Сервис имеет право по своему усмотрению заблокировать либо удалить учетную
                    запись Пользователя и отказать Пользователю в использовании своих сервисов (либо
                    их отдельных функций).
                  </p>
                </li>
                <li>
                  <p>
                    Персональная информация Пользователя, содержащаяся в учетной записи
                    Пользователя, получается Сервисом в соответствии с условиями Политики
                    конфиденциальности мессенджера telegram (
                    <a href="https://telegram.org/privacy#6-bot-messages">
                      <u>https://telegram.org/privacy#6-bot-messages</u>
                    </a>
                    ).
                  </p>
                </li>
                <li>
                  <p>
                    Нажимая кнопку start в диалоге с ботом или начиная диалог с ботом, Пользователь
                    соглашается передать свои данные Сервису.
                  </p>
                </li>
                <li>
                  <p>
                    Аутентификация и авторизация пользователя производится исключительно
                    мессенджером telegram, сервис не производит аутентификацию пользователя
                    самостоятельно.
                  </p>
                </li>
                <li>
                  <p>
                    Пользователь самостоятельно несет ответственность за безопасность (в том числе
                    устойчивость к угадыванию) выбранных им средств для доступа к учетной записи, а
                    также самостоятельно обеспечивает их конфиденциальность. Пользователь
                    самостоятельно несет ответственность за все действия (а также их последствия) в
                    рамках или с использованием сервиса под учетной записью Пользователя, включая
                    случаи добровольной передачи Пользователем данных для доступа к учетной записи
                    Пользователя третьим лицам на любых условиях (в том числе по договорам или
                    соглашениям). При этом все действия в рамках или с использованием сервиса под
                    учетной записью Пользователя считаются произведенными самим Пользователем.
                  </p>
                </li>
                <li>
                  <p>
                    Удаление учетной записи Пользователя произойдет при удалении учетной записи в
                    мессенджере telegram.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Условия использования сервиса.</h2>
              <ol>
                <li>
                  <p>
                    Пользователь самостоятельно несет ответственность перед третьими лицами за свои
                    действия, связанные с использованием Сервиса, в том числе, если такие действия
                    приведут к нарушению прав и законных интересов третьих лиц, а также за
                    соблюдение законодательства при использовании Сервиса.
                  </p>
                </li>
                <li>
                  <p>При использовании Сервиса Пользователь не вправе:</p>
                  <ol>
                    <li>
                      <p>несанкционированно собирать и хранить персональные данные других лиц.</p>
                    </li>
                    <li>
                      <p>нарушать нормальную работу сервиса.</p>
                    </li>
                    <li>
                      <p>
                        содействовать действиям, направленным на нарушение ограничений и запретов,
                        налагаемых Соглашением.
                      </p>
                    </li>
                    <li>
                      <p>
                        другим образом нарушать нормы законодательства, в том числе нормы
                        международного права.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Контент Пользователя</h2>
              <ol>
                <li>
                  <p>
                    Пользователь самостоятельно несет ответственность за соответствие содержания
                    размещаемого Пользователем контента требованиям действующего законодательства,
                    включая ответственность перед третьими лицами в случаях, когда размещение
                    Пользователем того или иного контента или содержание контента нарушает права и
                    законные интересы третьих лиц, в том числе личные неимущественные права авторов,
                    иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им
                    нематериальные блага.
                  </p>
                </li>
                <li>
                  <p>
                    Пользователь признает и соглашается с тем, что Администрация не обязана
                    просматривать контент любого вида, размещаемый и/или распространяемый
                    Пользователем. Пользователь осознает и согласен с тем, что он должен
                    самостоятельно оценивать все риски, связанные с использованием контента, включая
                    оценку надежности, полноты или полезности этого контента.
                  </p>
                </li>
                <li>
                  <p>
                    Пользователь осознает и соглашается с тем, что технология работы сервисов может
                    потребовать копирование (воспроизведение) контента Пользователя Сервисом, а
                    также переработки его для соответствия техническим требованиям того или иного
                    сервиса.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Исключительные права на содержание сервисов и контент</h2>
              <ol>
                <li>
                  <p>
                    Все объекты, доступные при помощи Сервиса, в том числе элементы дизайна, текст,
                    графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных,
                    музыка, звуки и другие объекты (далее &ndash; содержание сервисов), а также
                    любой контент, размещенный на сайте Сервиса, являются объектами исключительных
                    прав Сервиса, Пользователей и других правообладателей.
                  </p>
                </li>
                <li>
                  <p>
                    Использование контента, а также каких-либо иных элементов сервисов возможно
                    только в рамках функционала, предлагаемого тем или иным сервисом. Никакие
                    элементы содержания Сервиса, а также любой контент, размещенный на Сервисе, не
                    могут быть использованы иным образом без предварительного разрешения
                    правообладателя. Под использованием подразумеваются, в том числе:
                    воспроизведение, копирование, переработка, распространение на любой основе,
                    отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные
                    законодательством РФ или условиями использования Сервиса.
                  </p>
                </li>
                <li>
                  <p>
                    Использование Пользователем элементов содержания сервисов, а также любого
                    контента для личного некоммерческого использования, допускается при условии
                    сохранения всех знаков охраны авторского права, смежных прав, товарных знаков,
                    других уведомлений об авторстве, сохранения имени (или псевдонима)
                    автора/наименования правообладателя в неизменном виде, сохранении
                    соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо
                    предусмотренные законодательством РФ.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Сайты и контент третьих лиц</h2>
              <ol>
                <li>
                  <p>
                    Сервис может содержать ссылки на другие сайты в сети Интернет (сайты третьих
                    лиц). Указанные третьи лица и их контент не проверяются на соответствие тем или
                    иным требованиям (достоверности, полноты, законности и т.п.). Сервис не несет
                    ответственность за любую информацию, материалы, размещенные на сайтах третьих
                    лиц, к которым Пользователь получает доступ с использованием сервисов, в том
                    числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц,
                    рекламу и т.п., а также за доступность таких сайтов или контента и последствия
                    их использования Пользователем.
                  </p>
                </li>
                <li>
                  <p>
                    Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию
                    коммерческого или некоммерческого характера, размещенная на Сайте, не является
                    одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны
                    Сервиса.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Отсутствие гарантий, ограничение ответственности</h2>
              <ol>
                <li>
                  <p>
                    Пользователь использует Сервис на свой собственный риск. Сервис предоставляются
                    &laquo;как есть&raquo;. Администрация не принимает на себя никакой
                    ответственности, в том числе за соответствие сервисов целям Пользователя;
                  </p>
                </li>
                <li>
                  <p>
                    Администрация не гарантирует, что: сервис соответствует/будут соответствовать
                    требованиям Пользователя; сервис будут предоставляться непрерывно, быстро,
                    надежно и без ошибок; результаты, которые могут быть получены с использованием
                    сервиса, будут точными и надежными и могут использоваться для каких-либо целей
                    или в каком-либо качестве (например, для установления и/или подтверждения
                    каких-либо фактов); качество какого-либо продукта, услуги, информации и пр.,
                    полученных с использованием сервисов, будет соответствовать ожиданиям
                    Пользователя;
                  </p>
                </li>
                <li>
                  <p>
                    Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо
                    инструкции и руководства к действию и т.д.), доступ к которым Пользователь
                    получает с использованием сервисов Сервиса, Пользователь может использовать на
                    свой собственный страх и риск и самостоятельно несет ответственность за
                    возможные последствия использования указанных информации и/или материалов, в том
                    числе за ущерб, который это может причинить компьютеру Пользователя или третьим
                    лицам, за потерю данных или любой другой вред;
                  </p>
                </li>
                <li>
                  <p>
                    Администрация не несет ответственности за любые виды убытков, произошедшие
                    вследствие использования Пользователем данного Сервиса или отдельных
                    частей/функций;
                  </p>
                </li>
                <li>
                  <p>
                    При любых обстоятельствах ответственность Администрации в соответствии со
                    статьей 15 Гражданского кодекса России ограничена 10 000 (десятью тысячами)
                    рублей РФ и возлагается на него при наличии в его действиях вины.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Иные положения</h2>
              <ol>
                <li>
                  <p>
                    Настоящее Соглашение представляет собой договор между Пользователем и
                    Администрацией относительно порядка использования сервисов и заменяет собой все
                    предыдущие соглашения между Пользователем и Администрацией.
                  </p>
                </li>
                <li>
                  <p>
                    Настоящее Соглашение регулируется и толкуется в соответствии с законодательством
                    Российской Федерации. Вопросы, не урегулированные настоящим Соглашением,
                    подлежат разрешению в соответствии с законодательством Российской Федерации. Все
                    возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
                    разрешаются в порядке, установленном действующим законодательством Российской
                    Федерации, по нормам российского права. Везде по тексту настоящего Соглашения,
                    если явно не указано иное, под термином &laquo;законодательство&raquo;
                    понимается как законодательство Российской Федерации, так и законодательство
                    места пребывания Пользователя.
                  </p>
                </li>
                <li>
                  <p>
                    В отношении услуг, оказываемых в рамках настоящего Соглашения на безвозмездной
                    основе, нормы о защите прав потребителей, предусмотренные законодательством
                    Российской Федерации, не могут быть применимыми к отношениям между Пользователем
                    и Администрацией.
                  </p>
                </li>
                <li>
                  <p>
                    В случае если получение определенного набора услуг подразумевает оплату, то
                    внесение платы производится Пользователем в порядке предоплаты через эквайринг
                    партнеров Сервиса.
                  </p>
                </li>
                <li>
                  <p>
                    Ничто в Соглашении не может пониматься как установление между Пользователем и
                    Яндексом агентских отношений, отношений товарищества, отношений по совместной
                    деятельности, отношений личного найма, либо каких-то иных отношений, прямо не
                    предусмотренных Соглашением.
                  </p>
                </li>
                <li>
                  <p>
                    Если по тем или иным причинам одно или несколько положений настоящего Соглашения
                    будут признаны недействительными или не имеющими юридической силы, это не
                    оказывает влияния на действительность или применимость остальных положений
                    Соглашения.
                  </p>
                </li>
                <li>
                  <p>
                    Бездействие со стороны Сервиса в случае нарушения Пользователем либо иными
                    пользователями положений Соглашений не лишает Администрацию права предпринять
                    соответствующие действия в защиту своих интересов позднее, а также не означает
                    отказа Администрации от своих прав в случае совершения в последующем подобных
                    либо сходных нарушений.
                  </p>
                </li>
                <li>
                  <p>
                    Настоящее Соглашение составлено на русском языке и в некоторых случаях может
                    быть предоставлено Пользователю для ознакомления на другом языке. В случае
                    расхождения русскоязычной версии Соглашения и версии Соглашения на ином языке,
                    применяются положения русскоязычной версии настоящего Соглашения.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </Box>
      </Template>
    </div>
  );
};

const Terms = styled(TermsComponent)`
  h1 {
    margin-bottom: 25px;
  }
  h2 {
    margin-top: 35px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 27px;
  }
`;

export default Terms;
