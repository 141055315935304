import { PaymentActionTypes, PaymentStart, PaymentStartAction } from './types';

export const payment = (payload: PaymentStart & { callback: Function }): PaymentStartAction => ({
  type: PaymentActionTypes.START,
  payload,
});

export const paymentComplete = () => ({
  type: PaymentActionTypes.COMPLETE,
});

export const paymentError = (error: any) => ({
  type: PaymentActionTypes.ERROR,
  payload: error,
});
