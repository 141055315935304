import React from 'react';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '../../utils/helpers';
import Tag from '../Tag';
import Progress from '../Progress';

const Cards: any = styled.div`
  .card {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    margin-bottom: 8px;
  }
`;

const CardStyled = styled.div`
  .card {
    display: flex;
    padding: 15px 0;
    cursor: pointer;
  }
  .card__title {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 2px;
  }
  .card__thumbnail {
    background-color: #eee;
    border-radius: 5px;
    flex: 0 0 70px;
    height: 70px;
    margin-right: 14px;
    overflow: hidden;

    img {
      height: 70px;
    }
  }
  .card__content {
    display: flex;
    flex-direction: column;
    flex: 100% 0;
  }
  .card__progress {
    margin-top: auto;
  }
`;

const TagStatusColors: any = {
  'in progress': { color: '#ffedb6', textColor: '#fea848' },
  done: { color: '#d2ffd9', textColor: '#52d868' },
  available: { color: '#566fff', textColor: '#fff' },
  pay: { color: '#ffe674', textColor: '#ad8e00' },
};

const coverPlaceholder = {
  default:
    'https://cdn.shoplightspeed.com/shops/619878/themes/45/assets/placeholder-image.png?20190125091319',
  dark: 'https://i.imgur.com/rldLpqy.png',
};

type Props = {
  imageSrc: string;
  progress: string;
  status: string;
  title: string;
  theme: 'default' | 'dark';
  fromLang: string;
  toLang: string;
};

const Card = ({
  imageSrc,
  progress,
  status,
  title,
  theme = 'default',
  fromLang,
  toLang,
}: Props) => (
  <CardStyled>
    <div className="card">
      <div className="card__thumbnail">
        <img src={imageSrc || coverPlaceholder[theme]} alt="" />
      </div>
      <div className="card__content">
        <div className="card__title">{title}</div>
        <div>
          <Tag {...TagStatusColors[status]} style={{ fontWeight: 700 }}>
            {capitalizeFirstLetter(status)}
          </Tag>
          <Tag textColor="#333" style={{ fontWeight: 700, textTransform: 'uppercase' }}>
            {fromLang} &#8594; {toLang}
          </Tag>
        </div>

        {typeof progress === 'number' && (
          <div className="card__progress">
            <Progress progress={progress} />
          </div>
        )}
      </div>
    </div>
  </CardStyled>
);

Cards.Card = Card;

export default Cards;
