import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Player from '../Player';
import * as gtag from '../../lib/gtag';
import { Song } from '../Song';
import * as appActions from '../../modules/app/actions';
import Tour from '../Tour';
import SuccessSong from '../SuccessSong';
import SubscribePromo from '../SubscribePromo';
import { Box } from '../styled';
import { SongType } from '../../modules/song/types';

type Props = {
  song: SongType;
  changeTheme: Function;
  theme: string;
  changeTabView?: typeof appActions.changeTabView;
  tabView: 'parallel' | 'alternation' | 'change';
  introVisited: boolean;
  onCloseIntro: Function;
  guest: boolean;
  price?: string;
};

type State = {
  note: string;
  activeLine: string;
  manualPause: boolean;
  success: boolean;
};

const getIdFromYoutubeLink = (link: string) => link.split('?v=')[1].split('&')[0];

class Playlist extends Component<Props, State> {
  private playerRef = React.createRef();

  state = {
    note: '',
    activeLine: '',
    manualPause: false,
    success: false,
  };

  startTimePointsToLineIdMap = new Map<number, string>();

  tabHandle = (tabView: 'parallel' | 'alternation' | 'change') => {
    const { changeTabView } = this.props;

    // Send information about change view to Google Analytics.
    gtag.event({
      action: 'Change View',
      category: 'Tabs',
      label: tabView,
    });

    if (changeTabView) changeTabView(tabView);
  };

  componentDidMount = () => {
    const { song } = this.props;
    song.lyrics.stanzas.forEach((s: any, stanzaIndex: any) => {
      s.lines.forEach((l: any, lineIndex: any) => {
        l.startTime &&
          this.startTimePointsToLineIdMap.set(l.startTime, `${stanzaIndex}${lineIndex}`);
      });
    });
  };

  noteHandle = (note: string) => this.setState(() => ({ note }));

  timeChanged = (time: number) => {
    let lastActiveKey: number = this.startTimePointsToLineIdMap.keys().next().value;
    if (!lastActiveKey) {
      return;
    }
    this.startTimePointsToLineIdMap.forEach((_, key) => {
      lastActiveKey = time >= key ? key : lastActiveKey;
    });

    if (this.startTimePointsToLineIdMap.get(lastActiveKey) !== this.state.activeLine) {
      // @ts-ignore
      this.setState(() => ({
        activeLine: this.startTimePointsToLineIdMap.get(lastActiveKey),
      }));
    }
  };

  onExplanationStateChange = (opened: boolean) => {
    const { manualPause } = this.state;

    if (manualPause) return false;

    if (this.playerRef.current)
      // @ts-ignore
      this.playerRef.current[opened ? 'pauseVideo' : 'playVideo']();
  };

  render() {
    const {
      tabView,
      song,
      theme,
      changeTheme,
      introVisited,
      onCloseIntro,
      guest,
      price,
    } = this.props;
    const { activeLine, success } = this.state;

    return (
      <div>
        <Helmet>
          <title>{`${song.performer} - ${song.title}`}</title>
        </Helmet>

        {!success ? (
          <>
            <Song
              {...song}
              tabView={tabView}
              onTabHandle={this.tabHandle}
              onNoteHandle={this.noteHandle}
              currentNote={this.state.note}
              theme={theme}
              changeTheme={changeTheme}
              activeLineId={activeLine}
              payed={song.payed}
              explanationStateChange={this.onExplanationStateChange}
              onSuccess={() => this.setState({ success: true })}
              guest={guest}
              price={price}
            />
            <Player
              // @ts-ignore
              ref={this.playerRef}
              id={getIdFromYoutubeLink(song.url)}
              timeChanged={this.timeChanged}
              visible={song.payed}
              onManualPlayStateChange={(state) => this.setState({ manualPause: state === 'pause' })}
            />
            {!introVisited && <Tour onClose={onCloseIntro} />}
          </>
        ) : (
          <Box maxWidth="600px" margin="0 auto">
            {guest ? (
              <SubscribePromo
                songId={song.hash}
                playlistId={song.playList}
                onBack={() => this.setState({ success: false })}
              />
            ) : (
              <SuccessSong
                songId={song.hash}
                playlistId={song.playList}
                onBack={() => this.setState({ success: false })}
              />
            )}
          </Box>
        )}
      </div>
    );
  }
}

export default Playlist;
