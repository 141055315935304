import { select, fork, put, take } from 'redux-saga/effects';
import queryParams from 'query-string';
import { getUser, setUserParams } from '../auth/actions';
import { setToken } from '../../api/request';
import { AppState } from '../../redux/store';
import { VERSION } from '../../config/env';
import { setAppVersion } from './actions';
import { getSong } from '../song/actions';
import { SongActionTypes } from '../song/types';
import { AuthActionTypes } from '../auth/types';

function* initAppSaga() {
  const params = yield select((state) => state.router.location.search);
  const storeAuth = yield select((state) => state.auth);

  const parsedParams: {
    user?: string;
    song?: string;
    token?: string;
    playlist?: string;
  } = queryParams.parse(params);

  const token = parsedParams.token || storeAuth.token;
  const song = parsedParams.song || storeAuth.song;
  const userId = parsedParams.user || (storeAuth.user && storeAuth.user.id);
  const playlist = parsedParams.playlist;

  // Set gtag params
  window.gtag('js', new Date());
  window.gtag('set', { user_id: userId });

  // Get or reload user
  if (parsedParams.user) {
    yield put(getUser(parsedParams.user));
    yield take();
  }

  // Persist required params
  if (song && token) {
    setToken(token);
    yield put(setUserParams({ song, token }));
  }

  // Check App version
  const appVersion = yield select((state: AppState) => state.app.version);

  if (appVersion !== VERSION) {
    yield put(setAppVersion(VERSION));
    localStorage.removeItem('redux');
  }

  // Only when shared link
  if (song && playlist) {
    yield put(getSong({ songId: song, playlistId: playlist }));
  } else if (song) {
    let user = storeAuth.user;
    // When we don't have any playlist param,
    // so let's pretend that we already log in and have user playlist data,
    // so grab song from it and put in into song.selected
    if (!storeAuth.user) {
      const userAction = yield take(AuthActionTypes.GET_USER_SUCCESS);
      user = userAction.payload;
    }
    yield put({
      type: SongActionTypes.GET_SONG_SUCCESS,
      payload: user.availableSongs[song],
    });
  }
}

export function* RequestsSagaWatcher() {
  yield fork(initAppSaga);
}

export default RequestsSagaWatcher;
