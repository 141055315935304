import { PlaylistActionTypes } from './types';

export const getPlaylist = ({ slug }: { slug: string }) => ({
  type: PlaylistActionTypes.GET_PLAYLIST_REQUEST,
  payload: { slug },
});

export const subscribeToPlaylist = (payload: { userId: string; slug: string }) => ({
  type: PlaylistActionTypes.SUBSCRIBE_TO_PLAYLIST_REQUEST,
  payload,
});
