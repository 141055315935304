import React from 'react';
import styled from 'styled-components';

const TitleStyled = styled.div`
  margin: 10px 0 2px;
  .title__main {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 8px;
    line-height: 1.2em;
  }
`;

const DescriptionStyled = styled.div`
  font-size: 12px;
  color: #cccccc;
`;

const Title = ({ children, description }: any) => (
  <TitleStyled>
    <h2 className="title__main">{children}</h2>
    <DescriptionStyled>{description}</DescriptionStyled>
  </TitleStyled>
);

export default Title;
