declare global {
  interface Window {
    gtag: any;
    ENV: {
      APP_ENV: string;
      API_URL: string;
      SENTRY_DSN: string;
      VERSION: string;
      GA_TRACKING_ID: string;
    };
  }
}

// Common variables
export const APP_ENV = window.ENV.APP_ENV || 'localhost';
export const API_URL = window.ENV.API_URL || '/api';
export const VERSION = window.ENV.VERSION || '';

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY;
