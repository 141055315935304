import { fork } from 'redux-saga/effects';
import appSaga from '../modules/app/saga';
import authSaga from '../modules/auth/saga';
import librarySaga from '../modules/library/saga';
import playlistSaga from '../modules/playlist/saga';
import songSaga from '../modules/song/saga';
import trackingSaga from '../modules/tracking/saga';
import sentrySaga from '../modules/sentry/saga';
import payment from '../modules/payment/saga';
import { APP_ENV } from '../config/env';

export default function* rootSaga() {
  if (APP_ENV !== 'localhost') yield fork(sentrySaga);
  yield fork(trackingSaga);
  yield fork(appSaga);
  yield fork(authSaga);
  yield fork(librarySaga);
  yield fork(librarySaga);
  yield fork(playlistSaga);
  yield fork(songSaga);
  yield fork(payment);
}
